*,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.shadow1 {
  box-shadow: $box-shadow;
}
.status-tag {
  padding: 5px 12px;
  border-radius: 30px !important;
  width: 100px;
  text-align: center;
  font-size: 13px;
  display: inline-block;
  border-radius: 3px;
  color: #fff;
  text-transform: capitalize;
  background-color: grey;

  &.status-approved {
    background-color: green;
  }

  &.status-pending {
    background-color: orange;
  }

  &.status-rejected {
    background-color: red;
  }

  &.status-disable {
    background-color: gray;
  }

  &.status-active {
    background-color: blue;
  }
}

.custom-mui-table {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .MuiTableHead-root {
    background-color: $brand-color;
    .MuiTableCell-root {
      color: #fff !important;
      background-color: $brand-color;
    }
  }
  .MuiTableCell-root {
    text-transform: capitalize !important;
  }
  .table-title-first {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.4;
    color: $brand-color;
  }
}

.MuiButton-root {
  text-transform: capitalize !important;
}
.premium-box {
  background-image: linear-gradient(to right, #3646c7, #bea6e9);
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  width: 200px;
}
// applicant's box
.applicant-box {
  padding: 15px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;

  .applicant-box-header {
    .st-checkbox {
      display: flex;
      align-items: center;
      .MuiFormControlLabel-root {
        margin-bottom: 0;
      }
    }
    .st-name {
      .MuiFormControlLabel-label {
        font-size: 18px;
        margin-left: 5px;
        font-weight: 600;
        text-transform: capitalize;
      }

      .st-name-tag {
        svg {
          margin-right: 5px;
          font-size: 15px;
        }
        font-size: 11px;
        background: #d7edfb;
        padding: 0 15px;
        border-radius: 15px;
        margin-left: 10px;
        color: #1280f5;
        height: 26px;
        line-height: 26px;
      }
    }
    .st-exp-desc {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 500;
      color: rgb(73, 73, 73);
    }
    padding-bottom: 10px;
  }
  .applicant-box-content {
    border: 1px solid #eeedeb;
    border-left: 0;
    border-right: 0;
    padding: 10px 0;
    .table > :not(caption) > * > * {
      padding: 10px 0;
    }
  }
  .applicant-box-footer {
    padding: 15px 0 0 0;
  }
  .applicant-box-footer Button{
    margin-left: 5px;
  }

  .applied-text {
    font-size: 12px;
    font-weight: 400;
    color: rgb(73, 73, 73);
    line-height: 16px;
  }
  .first-column {
    width: 20%;
    width: 20%;
    font-weight: 600;
    font-size: 14px;

    color: $brand-color !important;
  }
  .info-icon {
    font-size: 18px;
  }

  .second-column {
    width: 80%;
    font-size: 13px;
    h6 {
      font-weight: 600;
      font-size: 13px;
    }
  }

  .st-skill-box {
    display: flex;
    width: auto;
    flex-direction: column;
    text-align: center;
    margin-bottom: 15px;
    text-align: left;
    text-transform: capitalize;
    .skill-name {
      line-height: 15px;
      font-size: 10px;
    }
  }
}

.sp-b {
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
}
.pt-100 {
  padding-top: 100px;
}

//forms css
.status-selectbox {
  .MuiSelect-outlined {
    padding: 3px;
  }
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.st-iconsbox {
  display: flex;
  flex-wrap: wrap;

  a {
    text-decoration: none;

    i {
      width: 40px;
      height: 40px;
      background-color: #1280f5;
      color: #fff;
      border-radius: 50%;
      margin-left: 10px;
      line-height: 40px;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.3s;

      &:hover {
        background-color: #0a5ebc;
        transform: scale(1.1);
      }
    }
  }
}

.page-breadcrumb {
  .MuiBreadcrumbs-li {
    a,
    p {
      font-size: 14px;
      color: grey;
    }
    a {
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        color: $secondary-color;
      }
    }
    p {
      font-weight: 400;
      color: $secondary-color;
    }
  }
}

.d-page-title {
  color: $blue1color;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 10px 0 28px;
}

.internship-list {
  font-size: 14px !important;
  letter-spacing: 0.7px;
  text-transform: capitalize;
}

.small-link {
  color: $secondary-color;
  font-size: 12px;
}

.login-page {
  .login-form-label {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 700;
    color: $blue1color;
  }
  input::placeholder {
    font-size: 12px !important;
    color: grey;
  }
  input {
    font-size: 14px !important;
    color: #000;
  }
}
.login-popup-footer {
  padding: 10px !important;
  justify-content: center !important;
  background-color: $blue2color;
  p {
    color: #fff;
    font-size: 12px;
    text-align: center;
    a {
      margin: 0 4px;
    }
    a:hover {
      color: #eeedeb;
    }
  }
}

.login-modal-wrapper {
  width: 400px;

  @media (max-width: 480px) {
    width: auto;
    input {
      font-size: 12px;
    }
    .MuiTab-textColorPrimary {
      font-size: 12px;
      padding: 8px;
    }
  }
}

// internshiplistmain page
.internship-summary-card {
  box-shadow: $box-shadow;
  border-radius: 12px;
  padding: 15px 15px 5px;
  position: relative;
  margin-bottom: 20px;

  .internship-company-logo {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 25px;
  }

  .internship-head-box {
    h6 {
      margin: 6px 0;
    }
  }

  .internship-summary-card-footer {
    padding: 10px 0 0 0;
    border-top: 1px solid #d1cfcf;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 12px;
      margin-bottom: 0;
      font-weight: 500;

      svg {
        font-size: 18px;
      }
    }
  }

  .skills-para {
    color: #0a80ff;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;

    .MuiChip-label {
      padding: 2px 12px;
      font-size: 11px;
    }
  }
}
.MuiChip-label {
  text-transform: capitalize;
}
.summary-info-box {
  display: flex;
  align-items: center;

  .internship-short-details {
    margin-right: 12px;
    font-size: 12px;
    text-transform: capitalize;

    svg {
      font-size: 14px;
    }
  }
}
.hi-title1 {
  font-size: 18px;
  font-weight: 700;
  color: #0a80ff;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 5px;
}
.hi-para1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.7px;
}

.blue-badge {
  border: 1px solid $secondary-color;
  font-size: 10px;
  padding: 2px 8px;
  border-radius: 19px;
  color: $secondary-color;
  font-weight: 500;
  margin-left: 10px;
}
.internshiplist-page {
  display: flex;
  align-items: baseline;
}

.filter-box {
  width: 300px;
  position: sticky;
  top: 90px;
  overflow-y: auto;

  margin-right: 15px;
  box-shadow: $box-shadow;
}
.internship-box {
  flex: 1;

  .grid-view {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .internship-summary-card {
      width: 49%;
    }
    .internship-summary-card .internship-company-logo {
      height: 30px;
    }

    .internship-summary-card .summary-info-box .internship-short-details {
      font-size: 12px;
      margin-right: 10px;
      text-align: center;
    }
    .hi-title1 .blue-badge {
      display: none;
    }
  }
}

.txt-primary {
  color: $secondary-color;
}
.text-cap {
  text-transform: capitalize;
}
@media (max-width: 800px) {
  .applicant-box .applicant-box-header .st-checkbox {
    width: 100%;
    justify-content: space-between;
    label {
      margin-right: 0 !important;
    }
  }

  .dashboard-page-box .dashboard-page-content-wrapper .dashboard-contentbox {
    padding-right: 0 !important;
  }
  .applicant-box .applicant-box-header .st-name .st-name-tag {
    font-size: 10px;
  }
  .application-box-wrapper {
    .applicant-box-footer {
      .MuiButton-outlined {
        font-size: 12px !important;
        padding: 6px 6px;
        margin-bottom: 10px;
      }
    }
    .sp-b {
      flex-direction: column;
    }
    .applicant-box .applicant-box-header .st-name .MuiFormControlLabel-label {
      font-size: 12px;
    }
    .st-iconsbox {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: end;
      padding: 10px 0;
    }
    .st-iconsbox a i {
      transform: scale(0.8);
    }
    table {
      tr,
      td {
        width: 100% !important;
        display: block;
      }
    }
  }

  //internship listing page

  .summary-info-box {
    margin: 6px 0;
  }
  .internshiplist-page .hi-title1 .blue-badge {
    display: none;
  }
  .hi-title1 {
    font-size: 14px;
  }
  .hi-para1 {
    font-size: 11px;
  }
  .d-page-title {
    margin-bottom: 12px;
  }
  //internship listing page end
}

.employer-dashboard-layout {
  min-height: calc(100vh - 138px);
}

.web-breadcremb {
  position: relative;

  .breadcrumb-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10%;

    .breadcrumb-text {
      font-weight: 700;
      margin-bottom: 6px;
      color: $secondary-color;

      font-size: 2rem;
    }
    .breadcrumb-subheading {
      font-weight: 600;
      text-align: right;
      margin-bottom: 0;

      font-size: 1rem;
    }
  }
}

.policy-page {
  padding: 50px 0;
  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  p,
  li {
    font-size: 14px;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  ul {
    padding-left: 24px;
  }
}
.login-page {
  max-width: 600px;
  margin: auto;
  padding: 50px 30px;
}
.MuiFormHelperText-root {
  margin-left: 0 !important;
}

//details page
.details-content-box {
  .i-company-logo {
    height: 50px;
  }
  .summary-info-box .internship-short-details {
    font-size: 14px;
  }
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 0;
}
.i-company-details {
  padding: 15px 15px;

  .i-company-name {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 10px;
  }
  .i-details-list {
    li {
      padding-left: 40px;
      margin-bottom: 10px;
      position: relative;
      font-size: 14px;
      i {
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background: #0b80ff;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        font-size: 15px;
      }
      strong {
        color: $blue1color;
        font-weight: 700;
        display: block;
      }
      .details-text {
        color: grey;
        text-transform: capitalize;
        font-weight: 400;
      }
    }
  }
}

.title-65 {
  font-size: 1.4rem;
  font-weight: 600;
  color: $secondary-color;
}

.skills-chip {
  background-color: #f2f5ff;
  text-transform: capitalize;
  font-size: 12px;
  padding: 4px 20px;
  margin: 10px 10px 10px 0;
  border-radius: 30px;
  display: inline-block;
  color: #0b80ff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
