body {
 font-family: "Poppins", sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heyinterns-website {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures full height */
}

.heyinterns-main-content {
  flex: 1; /* Pushes footer to the bottom */
}