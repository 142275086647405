.application-details-page {
  .heading-box {
    position: relative;
  }
  .applied-text {
    position: absolute;
    top: 0;
    right: 0;
  }
  .ad-box {
    .row {
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 10px;
      padding-bottom: 10px;

      &:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .col-sm-9 {
        p {
          font-size: 14px;
          color: grey;
        }
        .highlight-link {
          color: $secondary-color;
          font-size: 80%;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .sp-b {
      flex-direction: column;
      text-align: center;

      button {
        margin-bottom: 10px;
        font-size: 10px;
        padding: 5px 10px;
      }
    }
    .applied-text {
      position: static;
    }
  }
}
