.application-rightsidebar {
  margin-top: 10px;
  // position: sticky;
  // top: 124px;
  .admin-right-filter {
    width: 250px;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 800px) {
      margin-right: 0;
      box-shadow: none;
    }
  }

  .filter-box12 {
    margin-bottom: 15px;

    /* Target the Autocomplete placeholder */

    .MuiInputBase-root {
      width: 100%;
      input::placeholder {
        color: #888;
        font-size: 14px;
        font-family: "Poppins", sans-serif !important;
      }
    }

    .MuiTextField-root {
      margin-bottom: 16px;
    }

    .filter-box10-label {
      font-size: 12px;
      text-transform: capitalize;
      font-weight: 600;
      letter-spacing: 0.7px;
      margin-bottom: 4px;
      color: #3d3d3d;
    }
  }
}
