#account-menu {
  .naviagtion-link {
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 14px;
    color: black;
    text-decoration: none;
  }
  .userdate-dropdown {
    display: block;
    h6 {
      font-size: 14px;
      text-transform: capitalize;
      small {
        font-size: 10px;
        font-weight: 600;
        color: #0a80ff;
        margin-left: 10px;
      }
    }
    p {
      font-size: 12px;
      line-height: 1;
      font-weight: 400;
    }
  }
}
#header-navbar-dropdown {
  .naviagtion-link {
    width: 200px;
  }
}

.admin-header {
  display: flex;
  height: 75px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  position: sticky;
  top: 0;

  width: 100%;
  left: 0;
  background-color: $brand-color;
  padding-left: 320px;
  height: 74px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  z-index: 105;
  &.p-15 {
    padding: 0 15px;
  }
  .page-title-2 {
    color: $secondary-color;
  }
  .brand-name {
    color: $secondary-color;
    font-size: 24px;
    margin-left: 20px;
    font-weight: 800;
  }
  .navbar-outlined-btn {
    border-color: #0a80ff;
    margin: 0 6px;
    a {
      color: #fff;
    }
  }
  .navbar-solid-btn {
    border-color: #ffffff;
    background-color: #0a80ff;
    margin: 0 10px;
    a {
      color: #fff;
    }
  }
  .navbar-links {
    display: flex;
    align-items: center;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      align-items: center;

      li {
        position: relative;
        margin-right: 15px;

        a {
          color: #fff;
          padding: 10px 10px;
          font-size: 13px;
          letter-spacing: 0.7px;
          text-decoration: none;
          transition: color 0.3s;

          &:hover,
          &.active {
            color: #f0f0f0;
          }

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -4px; // Position it just below the text
            width: 0; // Start with width 0
            height: 2px; // Set height for the border
            background-color: #f0f0f0; // Border color
            transition: width 0.3s ease, left 0.3s ease; // Smooth transition
          }

          &:hover::after,
          &.active::after {
            width: 50%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

.mobile-navbar {
  .login-signup-btns {
    display: flex;
    flex-direction: column;
    button {
      margin: 10px 0;
    }
  }
  .navbar-links {
    ul {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 0;
      list-style-type: none;
      margin-bottom: 0;

      li,
      button {
        margin-bottom: 10px;
      }
      .chat-link {
        margin: 16px;
        // text-align: center;
        display: block;
      }
    }
    .account-menu-button {
      margin: 0;
      background-color: $secondary-color;
    }
    // .account-menu-link {
    //   display: block;
    //   background-color: $brand-color;
    //   margin: auto;
    //   text-align: center;
    //   .avatar-box {
    //     margin-left: 0;
    //   }
    // }
  }
}

@media (max-width: 800px) {
  .admin-header {
    position: static;
  }
}
