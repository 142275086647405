/*==========================
	Custom css
==========================*/
.screen-lock {
  overflow: hidden;
}
.review-testimonial {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.review-testimonial .item {
  width: 31%;
}
/* Header */
.fullwidth .logo-header {
  margin-right: 30px;
}
.fullwidth .header-nav .nav > li {
  padding: 20px 0;
  margin: 0px 3px;
}
.fullwidth .header-nav .nav > li.active > a,
.fullwidth .header-nav .nav > li:hover > a {
  background-color: var(--primary);
  color: #fff;
}
.extra-nav {
  padding: 20px 0;
}
.extra-nav .site-button {
  margin-left: 10px;
}
.fullwidth .header-nav .nav > li > a {
  padding: 10px 15px;
  border-radius: 4px;
}
.fullwidth .is-fixed .header-nav .nav > li:hover > a,
.fullwidth .is-fixed .header-nav .nav > li.active > a {
  color: #fff;
}
.fullwidth .is-fixed .header-nav .nav > li > a {
  color: #222845;
}
.navbar-toggler:focus {
  box-shadow: none;
}
/* Job Search Tabs */
.job-search-tabs .dez-tabs .nav-tabs {
  border-bottom: 0;
}
.job-search-tabs .dez-tabs .nav-tabs > li > a {
  border-radius: 10px 10px 0 0;
  padding: 15px 30px;
  border: 0;
  color: #000;
  font-weight: 700;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
.job-search-tabs .dez-tabs .nav-tabs li a:hover,
.job-search-tabs .dez-tabs .nav-tabs li a:active,
.job-search-tabs .dez-tabs .nav-tabs li a:focus,
.job-search-tabs .dez-tabs .nav-tabs li a.show,
.job-search-tabs .nav-tabs li a.show:focus,
.job-search-tabs .nav-tabs li a.show:hover,
.job-search-tabs .nav-tabs li a.show {
  padding: 15px 30px;
  background-color: #ff2a2a;
  font-weight: 700;
  color: #fff;
  border-bottom: 0;
}
.job-search-tabs .form-control,
.job-search-tabs .bootstrap-select .dropdown-toggle {
  border: 2px solid #eeeeee !important;
  font-size: 14px;
  font-family: Montserrat;
  border-radius: 4px;
  font-weight: 400;
  height: 50px;
  padding: 10px 20px;
  color: #606060;
}
.job-search-tabs .form-control::-moz-placeholder {
  color: #606060;
}
.job-search-tabs .site-button {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  padding: 13px 20px;
}
/* Job Search Tabs End */
.testimonial-text p {
  font-style: italic;
  font-size: 15px;
  color: #4a4a4a;
  line-height: 28px;
}
/* Job Search */
.other-job-list h3 {
  margin-bottom: 10px;
}
.other-job-list .job-box-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  background: #fff;
  margin-bottom: 10px;
  transition: 0.3s;
}
.info-box {
  padding: 50px;
}
.job-info {
  margin-bottom: 0;
}
.job-info li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.job-info li strong {
  font-weight: 600;
  color: #000;
}
/* Job Categories */
.job-categories .icon-bx-wraper {
  padding: 40px 10px;
  margin-bottom: 20px;
  background: var(--primary);
  border-radius: 10px;
  text-align: center;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}
.job-categories .icon-bx-wraper .dez-tilte {
  display: block;
  width: 100%;
  color: #000;
  font-size: 18px;
}
.job-categories .icon-bx-wraper .icon-content {
  position: relative;
  z-index: 1;
}
.job-categories .icon-bx-wraper .rotate-icon {
  position: absolute;
  font-size: 90px;
  color: #000;
  bottom: 10px;
  line-height: 90px;
  right: 20px;
  opacity: 0.05;
  text-align: right;
}
.job-categories .icon-bx-wraper:after {
  background-image: url(../images/categories-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  content: "";
  width: 100%;
  height: 110px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.job-categories .icon-bx-wraper:hover:after {
  height: 0;
}
.job-categories .icon-bx-wraper:hover {
  background-color: var(--primary);
  transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}
.job-categories .icon-bx-wraper .rotate-icon,
.job-categories .icon-bx-wraper .dez-tilte,
.job-categories .icon-bx-wraper {
  color: #fff;
}
.job-categories .icon-bx-wraper:hover .icon-md i {
  color: #fff;
}
/* Head Counter */
.head-counter h6 {
  color: #494949;
}
.head-counter-bx {
  margin-left: 30px;
}

@media only screen and (max-width: 991px) {
  .fullwidth .header-nav .nav > li {
    margin: 0;
  }
}
@media only screen and (max-width: 768px) {
  .other-job-list > li {
    padding: 30px 20px;
  }
  .app-info {
    text-align: center;
  }
}
@media only screen and (max-width: 575px) {
  .dez-bnr-inr .nav li a,
  .dez-bnr-inr .nav li a:hover,
  .dez-bnr-inr .nav li a:active,
  .dez-bnr-inr .nav li a:focus,
  .dez-bnr-inr .nav li a.show,
  .tabs-site-button .nav-tabs li a.show:focus,
  .tabs-site-button .nav-tabs li a.show:hover,
  .tabs-site-button .nav-tabs li a.show {
    padding: 10px 15px;
    font-size: 12px;
  }
  .job-search-tabs .dez-tabs .nav-tabs > li > a:active,
  .job-search-tabs .dez-tabs .nav-tabs > li > a:focus,
  .job-search-tabs .dez-tabs .nav-tabs > li > a:hover,
  .job-search-tabs .dez-tabs .nav-tabs > li > a,
  .job-search-tabs .dez-tabs .nav-tabs > li > a.show {
    padding: 15px 20px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 360px) {
  .dez-bnr-inr-entry h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .job-info li {
    padding: 4px 0;
  }
  .other-job-list img {
    text-align: center;
    margin-top: 20px;
  }
  .info-box {
    padding: 30px;
  }
}
.about-timeline::after {
  background-color: #ff2a2a;
}

/* post-job */
.post-job .dropdown-toggle {
  height: 50px;
  border-radius: 0;
  border: 2px solid #ededed !important;
}
.other-job-list.style-2 .job-box-list {
  margin-bottom: -1px;
  border: 1px solid #ededed;
  padding: 20px;
}
.other-job-list.style-2 .title-head {
  font-size: 20px;
}
.b-job-info li {
  list-style: none;
  float: left;
  border: 1px solid #d2d2d2;
  border-width: 1px 0;
  padding: 10px 0;
  margin-bottom: 15px;
  width: 33.33%;
}
.b-job-info .img img {
  width: 60px;
  border-radius: 50px;
  float: left;
  margin-right: 25px;
}
.other-job-list.style-2 .job-company-logo {
  width: 100px;
}
.blog-carousel .blog-post {
  margin-bottom: 0;
}

/* error page 404 */
.error-page .dz_error {
  font-size: 200px;
  line-height: 180px;
  margin-bottom: 0;
}
.error-page h3 {
  font-size: 90px;
  letter-spacing: 20px;
  line-height: 70px;
}
.error-page h4 {
  font-size: 45px;
  letter-spacing: 2px;
  line-height: 45px;
  margin-bottom: 30px;
}
.portfolio-box .dez-gallery-box {
  margin-bottom: 10px;
}
.portfolio-box .overlay-icon a {
  padding: 0;
  background: #fff;
  font-size: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 30px;
}

/* Theme BTN */
.site-button-secondry:after,
.site-button:after {
  background-color: #fff;
  content: "";
  height: 100%;
  left: -110%;
  opacity: 0.3;
  position: absolute;
  top: 0;
  transform: skewX(25deg);
  -o-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
  -webkit-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  width: 25px;
  z-index: -1;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.site-button:hover:after,
.site-button-secondry:hover:after {
  left: 110%;
}
.site-button-secondry:after {
  background-color: #fff;
}
.site-button,
.site-button-secondry {
  position: relative;
  z-index: 1;
  overflow: hidden;
  vertical-align: middle;
  padding: 15px 30px;
}

/* Gallery */
.gallery-box {
  padding: 150px 0;
}
.gallery-box ul {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
}
.gallery-box ul li {
  display: inline-block;
}
.gallery-box ul li a {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  display: block;
  font-size: 18px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  width: 40px;
}
/* testimonial-5 */
/* blog-style-1 */
.blog-style-1 .dez-post-meta {
  margin-bottom: 10px;
  padding: 25px 5px 0;
}
.blog-style-1 .dez-post-title .post-title {
  margin-top: 0;
}
.blog-md .dez-post-meta {
  padding: 0 5px 0;
}

/*  */
.job-search-form h2 {
  font-size: 50px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 50px;
}
.job-search-form h3 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 30px;
}
@media screen and (max-width: 991px) {
  .error-page .dz_error {
    font-size: 120px;
    line-height: 120px;
  }
  .error-page h3 {
    font-size: 60px;
    letter-spacing: 10px;
    line-height: 40px;
  }
  .error-page h4 {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .counter-style-1 .counter,
  .counter-style-2 .counter,
  .counter-style-3 .counter {
    font-size: 30px;
  }
  .counter-style-1 .icon {
    font-size: 25px;
    margin-right: 5px;
  }
}

.job-search-form .form-control {
  height: 60px;
  border-radius: 0 !important;
  margin-right: 0;
}
.job-search-form .form-control::-moz-placeholder,
.browse-job .form-control,
.submit-resume .form-control {
  font-size: 16px;
}
.job-search-form .input-group-prepend .site-button {
  height: 60px;
  border-radius: 0;
  padding: 0 30px;
  font-size: 18px;
}
.job-search-form form {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Job List */
.featurd-job-list .job-company-logo {
  width: 120px;
  float: left;
  padding: 5px;
  text-align: center;
  height: 100%;
}
.featurd-job-list .job-company-logo img {
  width: 70%;
}
.featurd-job-list .job-title,
.featurd-job-list .job-info-box {
  width: 50%;
  float: left;
}
.featurd-job-list .job-info-box {
  align-self: center;
}
.featurd-job-list .title-head {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
.featurd-job-list .job-box-list {
  clear: both;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  padding: 15px;
  transition: all 0.5s;
  border-left: 2px solid rgba(0, 0, 0, 0);
  background: #f6f6f6;
  border-radius: 5px;
  margin-bottom: 10px;
}
.featurd-job-list .job-info-box {
  text-align: right;
}
.featurd-job-list .job-box-list:hover {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

/* testimonial-5 */
.testimonial-5 {
  text-align: center;
}
.testimonial-5 .testimonial-text {
  border-radius: 10px;
  background-color: var(--white);
  color: #fff;
  transition: all 0.5s;
}
.active.center .testimonial-5 .testimonial-text {
}
.testimonial-5 .testimonial-text {
  border: 0;
}
.active .testimonial-5 .testimonial-text {
}
.active.center .testimonial-5 .testimonial-text {
  background-color: var(--primary);
}
.active.center .testimonial-5 .testimonial-text p {
  color: #fff;
}
.testimonial-5 .testimonial-detail {
  margin-top: 30px;
}
.active.center .testimonial-5 .testimonial-text:after,
.testimonial-5 .testimonial-text:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid (var9--white);
  transform: translate(-50%, -0%);
}
.testimonial-5 .testimonial-detail {
  padding: 0;
}
.active.center .testimonial-5 .testimonial-text:after {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid var(--primary);
}

/* browse-job */
.browse-job .widget-title {
  margin-bottom: 10px;
}
.browse-job .form-control,
.browse-job .btn.dropdown-toggle.btn-light,
.submit-resume .form-control,
.submit-resume .btn.dropdown-toggle.btn-light {
  background-color: var(--white);
  border: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1) !important;
}
.form-check-input {
  background-color: var(--rgba-primary-1);
  border: 0;
  margin-right: 5px;
}
.submit-resume .dropdown-toggle .caret,
.browse-candidates .dropdown-toggle .caret {
  top: 20px !important;
}
.browse-candidates .input-group {
  display: flex;
}
.browse-candidates .input-group .dropdown.bootstrap-select {
  width: auto !important;
}
.browse-candidates .input-group .dropdown.bootstrap-select .btn {
  padding: 10px 30px 10px 15px;
}
.job-bx-title .bootstrap-select div.dropdown-menu ul li a {
  font-size: 12px;
}
.browse-job .btn.dropdown-toggle.btn-light,
.submit-resume .btn.dropdown-toggle.btn-light {
  height: 50px;
}
.browse-job .btn.dropdown-toggle.btn-light > div,
.submit-resume .btn.dropdown-toggle.btn-light > div {
  height: auto;
}
.browse-job .form-control {
  height: 50px;
  border-radius: 5px !important;
  margin-right: 25px;
}
.submit-resume .form-control {
  height: 50px;
  border-radius: 5px !important;
}
.submit-resume textarea {
  height: 120px !important;
  padding: 20px;
}
.overlay-logo {
  width: 100px;
  z-index: 99;
}

/* bootstrap-select */
.bootstrap-select .btn:before {
  content: "\f078";
  position: absolute;
  right: 16px;
  font-size: 12px;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}
.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  color: #fff;
}

/* Resume */
.featurd-job-list.all-resume .job-company-logo img {
  width: 100%;
  padding-right: 15px;
}
.featurd-job-list.all-resume .job-title,
.featurd-job-list.all-resume .job-info {
  width: 100%;
  text-align: left;
}
.featurd-job-list.all-resume .job-info {
  margin-bottom: 10px;
}
.featurd-job-list.all-resume .job-info li {
  margin-right: 10px;
}
.featurd-job-list.all-resume .tag a {
  margin-bottom: 5px;
}

/* Find Job Box */
.find-job-bx {
  text-align: left;
}
.find-job-bx h2 {
  font-size: 45px;
  line-height: 60px;
  font-weight: 500;
}
.find-job-bx p {
  margin-bottom: 10px;
}
.find-job-bx form {
  padding: 30px 30px 5px 30px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.find-job-bx .input-group-text,
.find-job-bx .form-control {
  border-radius: 0;
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-color: var(--primary);
  height: 100%;
}
.find-job-bx .form-control {
  padding: 0;
  height: 50px;
  color: #000;
  font-size: 16px;
}
.find-job-bx .form-group {
  position: relative;
}
.find-job-bx .form-group label {
  color: #000;
  font-size: 15px;
  position: absolute;
  top: 10px;
  left: 0;
  margin: 0;
  font-family: rubik;
  font-weight: 400;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.find-job-bx .form-group.focused label {
  top: -12px;
  font-size: 12px;
  opacity: 0.4;
}
.find-job-bx .input-group-text {
  padding: 0;
}
.find-job-bx .dezPlaceAni .site-button {
  height: 50px;
}
.find-job-bx .bootstrap-select .dropdown-toggle,
.find-job-bx .bootstrap-select .dropdown-toggle:active,
.find-job-bx .bootstrap-select .dropdown-toggle:focus,
.find-job-bx .bootstrap-select .dropdown-toggle:hover {
  border-width: 0 0 2px 0 !important;
  border-color: var(--primary) !important;
  background-color: transparent !important;
  border-radius: 0;
  height: 50px;
  padding: 0 0 0 0;
  font-family: rubik;
  color: #000;
  font-size: 15px;
}
.find-job-bx .bootstrap-select .dropdown-toggle > div {
  height: auto;
}
.find-job-bx .bootstrap-select ul li {
  font-size: 15px !important;
}
.find-job-bx .bootstrap-select.btn-group .dropdown-toggle .caret {
  top: 20px;
  right: 0;
  font-size: 12px;
}
.find-job-bx .bootstrap-select.btn-group .dropdown-toggle .caret::before {
  content: "\f078";
}
.browse-job .bootstrap-select.btn-group .dropdown-toggle .caret {
  top: 15px;
}
.table-job-bx.browse-job .bootstrap-select.btn-group .dropdown-toggle .caret {
  top: 13px;
}

/* Post Job Bx */
.post-job-bx {
  margin: 0;
  padding: 0;
  list-style: none;
}
.post-job-bx,
.post-job-bx li,
.post-job-bx ul,
.cv-manager,
.cv-manager li,
.cv-manager ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.post-job-bx ul li,
.cv-manager ul li {
  display: inline-block;
  margin-right: 10px;
}
.post-job-bx ul li i,
.cv-manager ul li i {
  margin-right: 5px;
}
.cv-manager .job-post-company {
  margin-right: 20px;
}
.post-job-bx .post-bx {
  padding: 25px;
  background-color: #fff;
  border-left: 5px solid;
  border-color: var(--rgba-primary-1);
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  display: block;
  position: relative;
}
.post-job-bx .post-bx:hover {
  border-color: var(--primary);
}
.post-job-bx > li,
.cv-manager > li {
  margin-bottom: 30px;
}
.post-job-bx > li:last-child {
  margin-bottom: 0;
}
.post-job-bx > li:nth-of-type(2n + 1) .post-bx {
  background-color: var(--rgba-primary-05);
}
.job-post-company span {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: var(--rgba-primary-2);
  text-align: center;
  overflow: hidden;
}
.save-job .job-post-company {
  max-width: 65px;
}
.post-job-bx h4 {
  margin-bottom: 10px;
}
.salary-bx span {
  color: #000;
  font-family: rubik;
  font-size: 22px;
}
.job-time span {
  border-radius: 4px;
  background-color: var(--rgba-primary-1);
  display: block;
  font-size: 15px;
  padding: 2px 10px;
  display: inline-block;
  text-align: center;
  transition: all 0.5s;

  text-transform: capitalize;
}
.job-time a:hover span {
  color: var(--rgba-primary-1);
  transition: all 0.5s;
  color: #fff;
}

/* Candidates Box */
.candidates-are-sys {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  padding: 30px 20px;
}
.candidates-bx .testimonial-pic.radius {
  width: 75px;
  height: 75px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border: 4px solid #fff;
  margin-bottom: 25px;
}
.candidates-bx .testimonial-text {
  padding: 0;
  color: #000000;
  margin-bottom: 25px;
}
.candidates-bx .testimonial-text p {
  font-style: unset;
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 400;
}
.candidates-bx .testimonial-detail {
  padding: 0;
}
.testimonial-name {
  font-family: rubik;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.testimonial-position {
  font-family: rubik;
  font-size: 14px;
  font-style: inherit;
  text-transform: capitalize;
}
.candidates-bx {
  position: relative;
}
.candidates-bx:before,
.candidates-bx:after {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  color: var(--rgba-primary-05);
  font-size: 40px;
  top: 80px;
}
.candidates-bx:after {
  content: "\f10e";
  top: auto;
  bottom: -10px;
  right: 0;
}

/* Quote Box */
.quote-bx {
  background-image: url(../images/our-work/pic4.jpg);
  background-size: cover;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  padding: 30px 20px;
  z-index: 1;
  color: #fff;
}
.quote-bx:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000930;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 4px;
}
.quote-bx h4 {
  color: #fff;
}
.quote-bx p {
  opacity: 0.9;
}
.quote-info {
  z-index: 2px;
  position: relative;
}

/* City Box */
.city-bx {
  height: 180px;
  border-radius: 4px;
  background-size: cover;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.city-bx:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000930;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 4px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.city-bx:hover:after {
  background-color: var(--primary);
  opacity: 0.95;
}
.city-info {
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 20px 25px;
}
.city-info h5 {
  font-size: 22px;
  color: #fff;
  margin-bottom: 5px;
}
.city-info span {
  color: #fff;
  opacity: 0.9;
}

@media only screen and (max-width: 1191px) {
  .extra-nav .site-button {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  .fullwidth .header-nav .nav > li > a {
    padding: 10px 12px;
  }
  .job-search-form h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .header-nav.justify-content-start {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
}

@media only screen and (max-width: 991px) {
  .job-search-form h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .fullwidth .header-nav .nav > li,
  .fullwidth .is-fixed .header-nav .nav > li {
    padding: 0;
  }
  .fullwidth .header-nav .nav > li > a {
    border-radius: 0;
  }
  .nav.navbar-nav li:hover a i.fa-chevron-down {
    color: #fff;
  }
  .candidates-are-sys {
    margin-top: 30px;
  }
  .extra-nav {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .blog-post.blog-md .dez-post-media {
    width: 100%;
  }
  .blog-post.blog-md .dez-post-info {
    display: block;
    padding: 20px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .job-search-form .form-control,
  .job-search-form .input-group-prepend .site-button {
    height: 40px;
    border-radius: 0px !important;
    margin: 0;
    font-size: 13px;
  }
  .job-search-form .form-control:-moz-placeholder {
    font-size: 13px;
  }
  .featurd-job-list .job-box-list {
    display: block;
  }
  .featurd-job-list .job-title,
  .featurd-job-list .job-info-box {
    display: block;
    width: 100%;
    float: none;
    text-align: left;
  }
  .job-info {
    margin-top: 20px;
  }
  .featurd-job-list .job-company-logo {
    width: auto;
    text-align: left;
  }
  .job-info ul {
    text-align: left;
  }
  .job-info li {
    margin-right: 10px;
  }
  .find-job-bx h2 {
    font-size: 24px;
    line-height: 33px;
  }
  .find-job-bx form {
    padding: 30px 30px 30px 30px;
  }
  .head-counter {
    display: block !important;
  }
  .head-counter-bx {
    margin-left: 0;
    display: inline-block;
    margin-right: 0;
    width: 33.33%;
    float: left;
  }

  .head-counter-bx h2 {
    font-size: 24px;
  }
  .head-counter-bx h6 {
    font-size: 14px;
    font-weight: 400;
  }
  .blog-post.blog-md .dez-post-info {
    padding: 0;
  }
}
@media only screen and (max-width: 576px) {
  .job-search-form h2 {
    font-size: 30px;
    margin-bottom: 5px;
    line-height: 40px;
  }
  .job-search-form h3 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 28px;
  }
  .post-job-bx .d-flex {
    display: block !important;
  }
  .job-post-company {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .job-time {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 360px) {
  .featurd-job-list.all-resume .job-company-logo {
    display: none;
  }
}

.browse-job .search-order form .input-group {
  margin-bottom: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}
.browse-job .search-order form .input-group .form-control {
  margin-right: 0px;
  box-shadow: none !important;
}
.browse-job .search-order form .input-group .input-group-text {
  background: #fff;
  border: 0px;
}
.job-links {
  position: absolute;
  top: 25px;
  right: 25px;
}
.job-links i {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid var(--primary);
  line-height: 30px;
  text-align: center;
  margin-left: 5px;
}
.candidate-info .candidate-detail {
  padding: 30px 0px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  background-color: var(--white);
  border-radius: 4px 4px 0 0;
}
.candidate-info.onepage ul {
  border-radius: 4px;
}
.candidate-info.onepage ul li:first-child a {
  border-top: 0;
}
.canditate-des {
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  outline-offset: 3px;
  border-radius: 100%;
  width: 150px;
  height: 150px;
}
.canditate-des .upload-link {
  position: absolute;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #fff;
  top: 0;
  right: 0;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border-radius: 100%;
  color: var(--primary);
  overflow: hidden;
}
.candidate-info img {
  border-radius: 100%;
  width: 100%;
  background-color: #fff;
  padding: 5px;
}
.candidate-title {
  margin-top: 25px;
}
.candidate-info ul {
  list-style: none;
  margin-bottom: 0px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}
.candidate-info ul li a {
  width: 100%;
  padding: 15px 20px;
  display: inline-block;
  border-top: 1px solid var(--rgba-primary-1);
  color: #232323;
  border-left: 3px solid rgba(0, 0, 0, 0);
}
.nav-link:focus,
.nav-link:hover {
  color: var(--primary);
}
.candidate-info ul li a i {
  margin-right: 5px;
}
.candidate-info ul li a:hover,
.candidate-info ul li a.active {
  background: var(--rgba-primary-1);
  border-left-color: var(--primary);
}
.candidate-info ul li a span {
  transition: all 0.5s;
}
.candidate-info ul li a:hover span {
  margin-left: 10px;
  transition: all 0.5s;
}
.job-post-info .posted-info {
  background-color: var(--rgba-primary-1);
  padding: 10px;
  border-radius: 4px;
}
.browse-job-title .widget-title {
  margin: 10px 0;
}
.upload-link:hover {
  cursor: pointer;
}
.tooltip {
  border-radius: 5px;
  font-size: 13px;
  text-transform: capitalize;

  z-index: 99;
}
.update-flie {
  position: absolute;
  opacity: 0;
  z-index: 0;
  width: 100px;
  cursor: pointer;
  left: 0;
}
.canditate-des::before {
  content: "";
  position: absolute;
  background-color: var(--primary);
  height: 115%;
  width: 2px;
  left: 50%;
  top: -10px;
  z-index: -1;
}
.canditate-des {
  background-color: #fff;
  z-index: 99;
}
.canditate-des::after {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  width: 120%;
  background-color: var(--primary);
  height: 2px;
  z-index: -1;
}
.browse-job-sort .btn.dropdown-toggle.btn-default {
  width: 150px;
  float: right;
}

/* Alerts Job */
.table-job-bx thead th,
.table-job-bx tbody td {
  padding: 15px 20px;
}
.table-job-bx .job-links {
  padding: 20px 20px 20px 5px;
}
.job-bx {
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  padding: 20px 30px;
  border-radius: 4px;
  background-color: var(--white);
}
.card {
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  padding: 30px;
  border-radius: 4px;
  background-color: var(--white);
  border: 0;
}
.table-job-bx .job-name {
  font-weight: 600;
  color: #333333;
  font-size: 14px;
  font-family: "roboto", sans-serif;
}
.table-job-bx .criterias {
  font-size: 13px;
}
.table-job-bx .job-links {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
.table-job-bx thead th {
  background-color: var(--rgba-primary-2);
}
.table-job-bx tbody tr {
  border-bottom: 1px solid;
  border-color: var(--rgba-primary-2);
}
.table-job-bx tbody tr:nth-child(2n + 2) {
  background-color: var(--rgba-primary-05);
}

/* CV Manager */
.cv-manager > li {
  clear: both;
  position: relative;
  display: flex;
  border-bottom: 1px solid;
  border-color: var(--rgba-primary-1);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
/*  Job Profile */
.job-profile textarea.form-control {
  height: 150px;
  line-height: 24px;
}
.job-profile label,
.submit-resume label,
.browse-job label {
  color: #333;
}
.job-bx-title {
  border-bottom: 1px solid;
  border-color: var(--rgba-primary-1);
  padding: 10px 0;
  font-size: 22px;
  margin-bottom: 30px;
}
.pagination > li > a,
.pagination > li > span {
  display: flex;
  align-items: center;
}
.custom-file > input[type="file"] {
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
.custom-file {
  overflow: hidden;
  position: relative;
  padding: 12px;
  text-align: center;
  border: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1) !important;
  height: 50px;
  border-radius: 4px;
}
.save-job .job-post-company span {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  float: left;
}

/* Jobs Link */
.job-links .fa-download,
.job-links .fa-eye {
  background-color: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 7px;
  line-height: 16px;
}
.job-links .ti-trash {
  background-color: #e53232;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 7px;
}

/* Modal Box Info */
.modal-bx-info {
  z-index: 999999;
}
.modal-backdrop {
  z-index: 99999;
}
.modal-bx-info .modal-header .close {
  opacity: 1;
  font-size: 36px;
}
.modal-bx-info .modal-header {
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid;
  border-color: #e0e0e0;
  padding: 10px 20px;
  overflow: hidden;
}
.modal-bx-info .modal-header .modal-title {
  color: #333;
  margin-left: 20px;
  width: 100%;
  font-weight: 700;
  /* font-family: nunito; */
  font-size: 18px;
  align-self: center;
}
.modal-bx-info .modal-header .logo-img {
  width: 60px;
}
.modal-bx-info .modal-body li {
  list-style: none;
  padding: 5px 0;
  display: block;
  clear: both;
}
.modal-bx-info .modal-body li strong {
  color: #333;
  font-size: 16px;
  /* font-family: nunito; */
  font-weight: 600;
  display: flex;
  float: left;
  margin-right: 20px;
  width: 22%;
}
.modal-bx-info .modal-body li p {
  display: flex;
  margin-bottom: 0;
}
.modal-bx-info .modal-body {
  background-color: rgba(0, 0, 0, 0.01);
}
.modal-bx-info .modal-content {
  border-radius: 5px;
  border: 0;
  overflow: hidden;
}
.modal-bx-info .modal-header .close {
  border: 0;
  border-left: 1px solid;
  border-color: #e0e0e0;
  padding: 26px 20px;
  margin: -1rem -1rem -1rem auto;
}
.modal-bx-info .modal-footer {
  border-color: #e0e0e0;
  background-color: rgba(0, 0, 0, 0.04);
}
.job-bx-title .dropdown.bootstrap-select {
  width: 120px !important;
  float: right;
}
.job-bx-title .btn.dropdown-toggle.btn-light {
  box-shadow: unset !important;
  border: 1px solid var(--rgba-primary-1) !important;
  width: 120px;
  height: 40px !important;
  float: right;
  border-radius: 5px;

  font-size: 12px;
  padding: 12px;
}
.job-bx-title .btn.dropdown-toggle.btn-light:before {
  font-size: 12px;
}
.job-post-company {
  margin-right: 20px;
}

/* Browse Job */
.browse-job-find .find-job-bx {
  text-align: left;
  position: relative;
  top: -50px;
  background-color: #fff;
  border-radius: 5px;
}
.post-job-bx.browse-job-grid {
  margin: 0 -15px;
}
.post-job-bx.browse-job-grid > li {
  padding: 0 15px;
}
.post-job-bx.browse-job-grid .salary-bx span {
  font-size: 18px;
}

/* Company Info */
.company-info .update {
  right: -100px;
}

/* Transaction Table */
.amount,
.transfer,
.order-id,
.expired,
.application,
.table-job-bx .date {
  font-size: 14px;
}
.table-job-bx th {
  font-size: 16px;
}
.amount {
  font-weight: 700;
}
.expired.pending {
  color: #ed527b;
}
.expired.success {
  color: #25ad60;
}
.expired,
.expired.pending,
.expired.success {
  font-weight: 500;
}

/* Company Manage Job */
.company-manage-job .job-name a {
  font-size: 16px;
  color: #333;
}
.company-manage-job .job-post-info li {
  font-weight: 400;
}
/*  Login 2 */
.login-style2 .section-full {
  background-color: transparent;
}
.login-style2 {
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}
.login-style2 .login-footer span {
  font-size: 14px;
}
.login-style2 .login-footer {
  padding: 0 15px;
}
.login-style2 .section-full {
  width: 100%;
}
.login-style2 .logo {
  margin-bottom: 30px;
  width: 180px;
}
.login-2 p,
.login-2 .site-button-link {
}
.login-2 .site-button-link i {
  margin: 0;
}
.login-2 {
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  width: 400px;
  float: right;
  margin: 0;
  border-radius: 3px;
}
.login-footer .container {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px 0;
}
.login-footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.login-bx {
  padding-bottom: 0;
}
.login-form-bx .box-skew,
.login-form-bx .box-skew1 {
  height: 100vh;
  min-height: 100vh;
  position: relative;
}
.login-form-bx .box-skew1:after {
  content: "";
  background-image: url(../images/background/bg6.jpg);
  height: 100%;
  position: absolute;
  width: 120%;
  left: -30px;
  top: 0;
  background-size: cover;
  z-index: -1;
  transform: skew(-5deg);
}
.login-form-bx .box-skew1 {
  z-index: 1;
  background-color: var(--white);
}
.login-form-bx .box-skew .login-2 {
  box-shadow: none;
  position: absolute;
  z-index: 1;
  right: 20px;
}
.login-form-bx .box-skew .login-2:after {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  content: "";
  height: 100%;
  width: 200%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.login-form-bx .login-footer .container {
  z-index: 99;
  position: relative;
  border: 0;
}
.login-form-bx {
  padding-bottom: 0;
}
.login-style3 .login-form {
  margin: 0 40px 0 50px;
}
.login-style3 .skew-section {
  padding-left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
}
.login-style3 .form-group.text-left span {
  display: -webkit-inline-box;
  white-space: nowrap;
}
.login-style3 .mCustomScrollBox {
  width: 100%;
  height: auto;
}
.sticky-top {
  z-index: 99;
}
.text-black-light {
  color: #999;
}
.browse-job.login-style3 .form-control,
.browse-job.login-style2 .form-control {
  margin-right: 0;
}
.site-button.outline.active {
  color: #fff;
}
.job-time span {
  margin-bottom: 5px;
}
.dropdown-menu.open.show {
  z-index: 9;
}

@media only screen and (max-width: 991px) {
  .login-form-bx .box-skew .login-2:after {
    display: none;
  }
  .login-form-bx .box-skew1:after {
    width: 100%;
    left: 0;
    transform: unset;
  }
  .login-form-bx .box-skew .login-2 {
    right: 0;
  }
  .login-form-bx .box-skew .login-2 .tab-content.nav {
    width: 100%;
  }
}

/* Hover Effect */
.table-job-bx .job-name a:hover,
.browse-job-grid a:hover,
.post-job-bx .job-post-info li a:hover,
.cv-manager .job-post-info a:hover,
.browse-job .job-post-info a:hover,
.candidate-title a:hover,
.save-job .criterias a:hover,
.login-form .forget-pass:hover,
.login-form label a:hover,
.login-2 .forget-pass:hover,
.forget-pass:hover,
.category-list li a:hover {
  text-decoration: underline;
  color: #333;
}
.icon-bx-wraper .icon-content .dez-tilte:hover {
  text-decoration: underline;
}
.browse-job .posted-info a:hover {
  text-decoration: none;
  color: #fff;
}
.login-form .forget-pass {
  display: block;
  margin-top: 20px;
  text-align: center;
}
.login-form .nav {
  display: unset;
}
.login-form .site-button.button-md.btn-block {
  font-weight: 700;
}
.table-job-bx .feature {
  padding-right: 0;
}
.table-job-bx .job-name a,
.pricingtable-wrapper.style2 .site-button.white:hover span {
  color: #333;
}
.feature .form-check .form-check-input {
  border: 2px solid var(--primary);
}
.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
.form-check-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-05);
}

/* LIKE BTN ===================== */
/* The like-btn */
.like-btn {
  display: block;
  position: absolute;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: FontAwesome;
  top: 25px;
  right: 25px;
}
.like-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 1px solid var(--primary);
  border-radius: 20px;
}
.like-btn input:checked ~ .checkmark {
  background-color: var(--primary);
}
.checkmark:after {
  content: "\f08a";
  position: absolute;
  display: none;
}
.like-btn input:checked ~ .checkmark:before {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  left: 0;
  top: 0;
  border-radius: 20px;
  color: #fff;
  padding: 3px 6px;
}
.like-btn .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
}
.like-btn .checkmark:before {
  content: "\f004";
  left: 6px;
  top: 3px;
  position: absolute;
  font-size: 16px;
  font-family: "Font Awesome 5 Free";
  font-weight: normal;
  color: var(--primary);
}
.comment-reply-link:hover {
  color: #333 !important;
}
.job-post-info.m-a0 {
  margin: 0 !important;
}
.right-arrow {
  background-image: url(../images/sap.png);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.post-bx .job-post-info h5 {
  margin-right: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1024px) {
  .job-bx {
    overflow: auto;
  }
  .cv-manager .action-bx {
    right: 5px;
  }
  .header-nav .nav > li .sub-menu li > .sub-menu.left,
  .header-nav .nav > li .sub-menu li:hover .sub-menu.left {
    left: auto;
    right: 219px;
  }
}
@media only screen and (max-width: 768px) {
  .login-2 {
    width: 100%;
  }
  .table-job-bx table {
    width: 700px;
  }
}
@media only screen and (max-width: 600px) {
  .company-manage-job {
    min-width: 200%;
  }
  .login-style2 .max-w400.align-self-center {
    padding: 30px;
    max-width: 100%;
    text-align: center;
  }
  .login-style2 .logo {
    margin: 0 auto 30px;
  }
  .login-form-bx .box-skew {
    height: auto;
    min-height: auto;
    margin: 30px 0px -50px;
    z-index: 9;
  }
  .login-form-bx .box-skew .login-2 {
    position: relative;
    right: 0;
  }
  .login-form-bx .box-skew1 {
    min-height: 70vh;
    height: 70vh;
  }
  .login-form-bx .box-skew .login-2 .tab-content,
  .login-form-bx .box-skew .login-2::after {
    width: 100%;
  }
  .login-style2 .login-footer .text-black {
    color: #fff;
  }
  .login-style3 .tab-content .tab-pane {
    width: 100%;
  }
  .table-job-bx table {
    width: 800px;
  }
}
@media only screen and (max-width: 360px) {
  .job-bx-title .btn-group.bootstrap-select,
  .job-bx-title .btn.dropdown-toggle.btn-default {
    width: 120px !important;
  }
  .cv-manager > li {
    display: inline-block;
  }
  .cv-manager > li .job-post-company {
    margin-right: 15px;
  }
  .cv-manager .action-bx {
    position: unset;
  }
  .modal-bx-info .modal-body li strong {
    float: unset;
    width: 100%;
  }
  .company-manage-job {
    min-width: 300%;
  }
  .login-style2 .login-footer .float-left,
  .login-style2 .login-footer .float-right {
    text-align: center;
    float: unset !important;
    display: block;
  }
  .login-style3 .login-form {
    margin: 0 30px;
  }
  .table-job-bx table {
    width: 700px;
  }
}

/* Lead Form Modal */
.lead-form-modal {
  z-index: 999999;
}
.lead-form-modal .modal-body {
  padding: 0;
}
.lead-form-modal .modal-content,
.lead-form-modal .modal-dialog {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  min-width: 750px;
  border-radius: 5px;
  overflow: hidden;
}
.lead-form-modal .modal-body {
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  position: relative;
}
.lead-form-modal .lead-form {
  background-color: var(--white);
  padding: 60px 30px;
  text-align: center;
}
.lead-form-modal .form-group {
  margin-bottom: 15px;
}
.lead-form-modal .close {
  font-family: raleway;
  font-size: 45px;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  color: #333;
  z-index: 9;
  font-weight: 200;
  padding: 10px 15px;
  border: 0;
  background: transparent;
}
.modal-backdrop.in {
  opacity: 0.8;
}
.lead-form-modal .form-info {
  position: relative;
  z-index: 99;
  padding: 60px 30px;
}

/* Attach Resume */
.attach-resume .custom-file,
.attach-resume .custom-file .form-control {
  height: 150px;
  display: flex;
}
.list-row .list-line p {
  font-family: "Lato", sans-serif;
}
.list-row .list-line {
  list-style: none;
  border-bottom: 1px solid;
  border-color: #e1e7ff;
  padding: 20px 0;
  width: 100%;
}
.add-btn {
  margin-left: auto;
  align-self: center;
  max-width: 100%;
  margin-bottom: auto;
}
.list-row .list-line:last-child {
  padding-bottom: 0;
  border: 0;
}
.modal-bx-info.editor .modal-title {
  margin: 0;
}
.modal-bx-info.editor .modal-dialog {
  max-width: 600px;
}
.modal-bx-info.editor textarea {
  height: 120px;
}
.tag_complete .tags_container .tag .close::after {
  background: #bcbcbc;
  line-height: 22px;
}
.custom-control-label {
  cursor: pointer;
}

/* Profile Edit */
.profile-edit .canditate-des {
  border: 0;
  width: 100px;
  height: 100px;
  margin: 0 30px 30px 0;
  background-color: unset;
}
.profile-edit .canditate-des .upload-link {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.profile-edit .canditate-des:after,
.profile-edit .canditate-des:before {
  content: unset;
}
.profile-edit .candidate-info .candidate-detail {
  box-shadow: unset;

  font-weight: 400;
  display: flex;
  padding-top: 0;
  background-color: transparent !important;
}
.profile-edit .candidate-info ul li i {
  margin-right: 10px;
}
.profile-edit .candidate-info ul li {
  font-size: 15px;
  width: 50%;
  font-weight: 400;
  float: left;
  margin-bottom: 10px;
}
.profile-edit li {
  list-style: none;
}
.profile-edit .list-check {
  margin: 0;
}
.profile-edit .list-check li {
  padding: 2px 5px 2px 25px;
}
.profile-edit .list-check li::before {
  font-size: 12px;
}

/* pending-info */
.pending-info {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: var(--rgba-primary-8);
}

/* Progress Box */
.progress-box .progress {
  height: 3px;
}
.progress-box .progress-info {
  text-align: left;
  font-size: 14px;
  margin-bottom: 5px;
}
.progress-box .progress-info span {
  float: right;
}

/* Category Info */
.category-jobs-info {
  bottom: 50px;
  position: absolute;
}
.category-jobs-info .nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  overflow: hidden;
}
.category-jobs-info .nav ul li {
  display: inline-block;
  float: left;
}
.category-jobs-info .nav ul li a {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  display: block;
  padding: 10px 15px;

  font-size: 14px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.7);
}
.category-jobs-info .nav ul li:first-child a {
  border-left: 0;
}
/* category-list */
.category-list li {
  list-style: none;
  margin-bottom: 10px;
}
.category-bx li a {
  background-color: var(--rgba-primary-05);
  border: 1px solid;
  border-color: var(--rgba-primary-2);
  padding: 5px 10px 5px 0;
  border-radius: 5px;
  clear: both;
  display: -webkit-flex;
}
.category-bx li a .logo,
.category-bx li a span {
  align-self: center;
}
.category-bx li a img {
  width: 50px;
  border-right: 1px solid;
  border-color: var(--rgba-primary-2);
  margin-right: 10px;
  padding: 4px 10px;
  display: flex;
}

/* Company logo wg  */
.company-logo-wg li {
  border: 1px solid;
  border-color: #ebebeb;
  display: inline-block;
  float: left;
  line-height: 100px;
  margin: 0 -1px -1px 0;
  width: 20%;
  padding: 10px;
  text-align: center;
}
.company-logo-wg {
  list-style: none;
  padding: 0;
}
.company-logo-wg.sidebar li a {
  align-self: center;
}
.company-logo-wg.sidebar li {
  width: 33.6%;
  display: flex;
  height: 80px;
  padding: 0;
}
.job-bx .site-filters a {
  padding: 6px 15px;
  font-size: 14px;
}

/* Sidebar Filter */
.sidebar-filter {
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}
.sidebar-filter .title {
  font-size: 16px;
  padding: 10px 15px;
  margin: 0;
}
.sidebar-filter .acod-head .acod-title a,
.sidebar-filter .acod-head .acod-title .collapsed {
  padding: 10px 15px;
  border-width: 1px 0;
  border-radius: 0;
}
.sidebar-filter .panel {
  margin-bottom: -1px;
}
.sidebar-filter .acod-head .acod-title {
  font-size: 16px;
  margin-bottom: -1px;
}
.sidebar-filter .acod-head a.collapsed::after,
.sidebar-filter .acod-head a::after {
  font-size: 12px;
}
.sidebar-filter .form-check label {
  font-weight: 400;

  margin-bottom: 0;
}
.sidebar-filter .form-check label span {
  font-size: 14px;
  color: #909090;
}
.select-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  line-height: 40px;
  margin-right: 15px;
}

/* Candidates Profile */
.job-bx .dez-post-meta ul {
  margin: 0;
}
.skill-profile {
  height: 100%;
  width: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
}
.jobs-category-bx .job-bx {
  padding: 10px 20px;
}
.jobs-category-bx .job-bx .job-bx-title {
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.jobs-category-bx .job-bx .category-list {
  margin-bottom: 15px;
}
.recaptcha-bx {
  margin-bottom: 20px;
}
.job-alert-bx {
  padding: 10px 10px 10px 0px;
}

@media only screen and (max-width: 1024px) {
  .lead-form-modal .modal-content,
  .lead-form-modal .modal-dialog {
    min-width: 80%;
  }
  .header-nav .nav > li .sub-menu.left {
    left: auto;
  }
}
@media only screen and (max-width: 768px) {
  .lead-form-modal .modal-content,
  .lead-form-modal .modal-dialog {
    min-width: 90%;
  }
  .profile-edit .candidate-info ul li {
    width: 100%;
  }
  .category-jobs-info {
    bottom: 20px;
  }
  .job-search-form {
    bottom: 20px;
    position: relative;
  }
  .recaptcha-bx {
    margin-bottom: 5px;
  }
  .job-alert-bx {
    padding: 10px 10px 25px 10px;
  }
}
@media only screen and (max-width: 600px) {
  .modal-bx-info.editor .modal-dialog {
    max-width: 540px;
    margin-top: 30px !important;
  }
  .company-logo-wg li {
    width: 33.33%;
  }
  .company-logo-wg.sidebar li {
    padding: 15px;
    width: 33.33%;
  }
  .post-resume {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .profile-edit .candidate-info .candidate-detail {
    display: block;
  }
  .profile-edit .candidate-info ul li {
    width: 100%;
  }
  .company-logo-wg li {
    width: 50%;
  }
  .category-jobs-info .nav ul li a {
    font-size: 12px;
    padding: 10px 12px !important;
  }
  .job-alert-bx {
    padding: 0 0 25px 0;
  }
  .list-row .list-line h6,
  .job-bx .d-flex h5 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
  }
}

.btn-suport {
  right: 0;
  bottom: 50%;
  position: fixed;
  z-index: 99;
  box-shadow: 0px 0px 15px 1px rgba(81, 77, 92, 0.2);
  padding: 3px;
  border-radius: 5px 0 0 5px;
  background-color: #fff;
}
.btn-suport a {
  color: #c1bfd0;
  margin: 0;
  padding: 3px 5px;
  text-align: center;
  display: block;
  font-size: 12px;
  font-weight: 700;
}
.btn-suport a span {
  position: absolute;
}

/* nav-tabs-1 */
.nav-tabs-1 {
  margin-bottom: 30px;
  border-bottom: 0;
}
.nav-tabs-1 .nav-item {
  margin-right: 20px;
}
.nav-tabs-1 .nav-item .nav-link {
  border-width: 0;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 700;
  border-radius: 4px;
  background: var(--primary);
  color: #fff;
}
.nav-tabs-1 .nav-item .nav-link.active {
  background-color: #222845;
  color: #fff;
}
@media only screen and (max-width: 591px) {
  .nav-tabs-1 .nav-item {
    margin-right: 4px;
  }
}

/* create-box */
.create-box {
  padding: 50px;
}
@media only screen and (max-width: 591px) {
  .create-box img {
    width: 60px;
  }
  .create-box {
    padding: 30px;
  }
}
.browse-job .emp-res {
  margin-bottom: 60px;
}
.browse-job .emp-res h1 {
  font-size: 60px;
  font-weight: 700;
}
.browse-job .emp-res p {
  font-size: 20px;
}
@media only screen and (max-width: 1191px) {
  .browse-job .emp-res h1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 991px) {
  .browse-job .emp-res h1 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 15px;
  }
  .browse-job .emp-res {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 591px) {
  .browse-job .emp-res h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
  }
  .browse-job .emp-res p {
    font-size: 16px;
  }
}
.logo-header .logo-white {
  display: none;
}
.site-header .header-nav .logo-header {
  display: none;
}
@media only screen and (max-width: 991px) {
  .site-header .header-nav .logo-header {
    display: block;
    margin-right: 0;
  }
  .site-header .logo-header .logo {
    display: table-cell;
  }
}
@media only screen and (max-width: 676px) {
  .layout-btn {
    height: 34px;
    width: 34px;
    font-size: 18px;
    line-height: 34px;
  }
  .layout-btn i {
    line-height: 34px;
  }
}

/* main-slider */
.main-slider {
  position: relative;
}
.main-slider .container {
  z-index: 99;
  position: relative;
}
.main-slider:after {
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0.9) 40%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.9) 40%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.9) 40%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}
.lg-outer {
  z-index: 99999;
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}
.lg-backdrop {
  z-index: 99999;
  background-color: rgba(30, 30, 30, 0.9);
}
.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}
.lightimg {
  cursor: pointer;
}
.site-footer .form-control:focus {
  color: #000;
}
.site-footer .form-control:focus::placeholder {
  color: #000;
}
.site-footer .form-control::placeholder {
  color: #fff;
}

/* DARK AND LIGHT */
.layout-btn {
  display: inline-block;
  font-size: 22px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  height: 42px;
  line-height: 40px;
  width: 80px;
  background-color: var(--primary);
  padding: 4px !important;
  position: relative;
  z-index: 1;
  cursor: pointer;
  vertical-align: middle;
}
.layout-btn input {
  z-index: 9;
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.layout-btn:before,
.layout-btn:after {
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 500;
  width: 50%;
  text-align: center;
  color: #fff;
  transition: all 1s;
  height: 42px;
  line-height: 42px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.layout-btn:before {
  content: "\f185";
  left: 2px;
  color: var(--primary);
}
.layout-btn:after {
  content: "\f186";
  right: 2px;
  color: #fff;
}
.layout-btn .mode-label:after {
  content: "";
  width: calc(50% - 4px);
  display: block;
  height: 34px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  right: 50%;
  top: 4px;
  z-index: -1;
  transition: all 1s;
}
.layout-btn input:checked + .mode-label:after {
  right: 4px;
}

.btn-block {
  display: block;
  width: 100%;
}
/* ============================================ */
/* == job-board template index-3.html == */
/* == typography == */
.data-typography-1 h1,
.data-typography-1 h2,
.data-typography-1 h3,
.data-typography-1 h4,
.data-typography-1 h5,
.data-typography-1 h6 {
  color: #232323;
}
.data-typography-1 {
  color: #444444;
  font-family: "Montserrat", sans-serif;
}
.data-typography-1 .page-wraper {
  background: #fff;
}
.section-head.style-1 .section-title {
  font-size: 50px;
  font-weight: 600;
  line-height: 59px;
  margin-bottom: 0;
  color: var(--title);
}
.section-head.style-1 .section-title-2 {
  font-size: 50px;
  font-weight: 500;
  line-height: 59px;
  margin-bottom: 0;
  color: var(--title);
}
.section-head.style-1 .section-title-3 {
  font-size: 45px;
  font-weight: 600;
  line-height: 53px;
  margin-bottom: 0;
  color: var(--title);
}
.section-head.style-1 h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--primary);
  margin-bottom: 0;
}
.section-head.style-1 p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  color: var(--title);
}
.dz-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
.dz-text-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 0;
}
.dz-text-3 {
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0;
  color: #504b4b;
}
.site-button.style-1 {
  font-size: 16px;
  font-weight: 500;
  padding: 12px 25px;
  border-radius: 6px;
}
.section-head.style-1 {
  margin-bottom: 40px;
}
.main-bar.style-1 button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/* responsive */
@media screen and (max-width: 1400px) {
  .section-head.style-1 .section-title,
  .section-head.style-1 .section-title-2 {
    font-size: 45px;
  }
}
@media screen and (max-width: 1024px) {
  .section-head.style-1 .section-title,
  .section-head.style-1 .section-title-2 {
    font-size: 40px;
    line-height: 50px;
  }
  .section-head.style-1 .section-title-3 {
    font-size: 40px;
    line-height: 50px;
  }
  .dz-text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 767px) {
  .section-head.style-1 .section-title,
  .section-head.style-1 .section-title-2,
  .section-head.style-1 .section-title-3 {
    font-size: 34px;
    line-height: 38px;
    margin-bottom: 10px;
  }
  .section-head.style-1 h6 {
    margin-bottom: 8px;
  }
  .site-button.style-1 {
    font-size: 14px;
    padding: 12px 22px;
  }
  .section-head.style-1 p {
    font-size: 14px;
    line-height: 22px;
  }
  .section-head.style-1 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px) {
  .section-head.style-1 .section-title,
  .section-head.style-1 .section-title-2,
  .section-head.style-1 .section-title-3 {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 0;
  }
  .section-head.style-1 p {
    font-size: 14px;
    line-height: 22px;
  }
  .section-head.style-1 {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 480px) {
  .section-head.style-1 .section-title,
  .section-head.style-1 .section-title-2,
  .section-head.style-1 .section-title-3 {
    font-size: 25px;
    line-height: 30px;
  }
}
/* Header */
/* .site-header.style-1{
	overflow: hidden;
} */
.main-bar.style-1 {
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
}
.main-bar.style-1 .header-nav .nav > li > a {
  font-size: 15px;
  font-weight: 400;
}
.main-bar.style-1 .header-nav {
  float: right;
}
.main-bar.style-1 .layout-btn {
  margin-left: unset !important;
}
@media screen and (max-width: 1380px) {
  .main-bar.style-1 .header-nav .nav > li .sub-menu.right {
    right: 0;
  }
  .main-bar.style-1 .header-nav .nav > li .sub-menu.right li > .sub-menu,
  .main-bar.style-1 .header-nav .nav > li .sub-menu.right li:hover .sub-menu {
    left: unset;
    right: 0;
  }
}
@media screen and (max-width: 1199px) {
  .main-bar.style-1 .extra-nav {
    display: none;
  }
}
/* Header-End */
/* Banner */
.main-bnr {
  position: relative;
  background: var(--rgba-primary-05);
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}
.main-bnr:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../images/back-lines.png);
  z-index: -1;
}
.main-bnr h5 {
  font-weight: 500;
  color: var(--primary);
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}
.main-bnr h1 {
  font-weight: 700;
  font-size: 65px;
  line-height: 75px;
}
.main-bnr h6 {
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.find-job-bx.style-1 {
  margin-bottom: 20px;
}
.find-job-bx.style-1 form {
  padding: 16px 20px 16px 30px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0px 10px 60px 0px var(--rgba-primary-2);
}
.find-job-bx.style-1 form .form-group {
  margin-bottom: 0;
}
.find-job-bx.style-1 form .form-group .input-group .input-group-text,
.find-job-bx.style-1 form .form-group .input-group .form-control {
  border: 0;
}
.find-job-bx.style-1 form .form-group .input-group .form-control {
  padding: 0 0 0 10px;
  height: unset;
  color: #000;
}
.find-job-bx.style-1 form .form-group .input-group .form-control::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #7f93b6;
  text-transform: none;
}
.find-job-bx.style-1 form .site-button {
  padding: 14px 25px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}
.find-job-bx.style-1 .dezPlaceAni .site-button {
  height: unset;
}

.main-bnr .banner-content .dz-searches h4 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #142238;
}
.main-bnr .banner-content .dz-searches span {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: rgba(127, 147, 182, 1);
  margin-left: 5px;
}

.banner-media {
  position: relative;
  z-index: -1;
}
.banner-media .banner-main-media {
  position: relative;
  z-index: 1;
}
.banner-media .banner-media-bg [class*="bnr"] {
  position: absolute;
  background-size: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.banner-media .banner-media-bg {
  position: absolute;
  width: 970px;
  height: 970px;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
}
.banner-media .banner-media-bg .bnr-circle1 {
  background-image: url(../images/dashed-circle1.svg);
  width: 55%;
  height: 55%;
  animation: bnr-circle1 20s linear infinite;
}
.banner-media .banner-media-bg .bnr-circle1:after,
.banner-media .banner-media-bg .bnr-circle1:before {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary);
  position: absolute;
  border-radius: 12px;
}
.banner-media .banner-media-bg .bnr-circle1:before {
  right: -1%;
  top: 50%;
}
.banner-media .banner-media-bg .bnr-circle1:after {
  left: 5.1%;
  top: 25%;
}
.banner-media .banner-media-bg .bnr-circle2 {
  background-image: url(../images/dashed-circle2.svg);
  width: 70%;
  height: 70%;
  animation: bnr-circle2 45s linear infinite;
}
.banner-media .banner-media-bg .bnr-circle3 {
  background-image: url(../images/dashed-circle3.svg);
  width: 100%;
  height: 100%;
  animation: bnr-circle3 90s linear infinite;
}
.banner-media .banner-media-bg .bnr-circle1 img {
  width: 50px;
}
.banner-media .banner-media-bg .bnr-circle1 .banner-icon1 {
  right: -6px;
  bottom: 10%;
  filter: blur(1px);
  position: absolute;
  animation: bnr-circle1-icon1 20s linear infinite;
  box-shadow: 0px 10px 15px 0px rgba(168, 126, 0, 0.5);
}
.banner-media .banner-media-bg .bnr-circle1 .banner-icon2 {
  left: 3px;
  top: 56%;
  width: 50px;
  filter: blur(1px);
  position: absolute;
  animation: bnr-circle1-icon1 20s linear infinite;
  box-shadow: 0px 10px 15px 0px rgba(168, 126, 0, 0.5);
}
.banner-media .banner-media-bg .bnr-circle1 .banner-icon3 {
  right: 5px;
  top: 20%;
  position: absolute;
  animation: bnr-circle1-icon1 20s linear infinite;
}
.banner-media .banner-media-bg .bnr-circle2 img {
  position: absolute;
}
.banner-media .banner-media-bg .bnr-circle2 .banner-icon1 {
  left: 8%;
  top: 25%;
  animation: bnr-circle2-icon1 45s linear infinite;
}
.banner-media .banner-media-bg .bnr-circle2 .banner-icon2 {
  right: -10%;
  top: 50%;
  animation: bnr-circle2-icon1 45s linear infinite;
}
.banner-media .banner-media-bg .bnr-circle2 .banner-icon3 {
  left: 4%;
  bottom: 20%;
  animation: bnr-circle2-icon1 45s linear infinite;
}
/* -- */
@keyframes bnr-circle1 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes bnr-circle1-icon1 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@keyframes bnr-circle2 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes bnr-circle2-icon1 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes bnr-circle3 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.main-bnr .back-circle,
.main-bnr .back-circle2 {
  position: absolute;
  width: 400px;
  height: 400px;
  opacity: 0.3;
  filter: blur(250px);
  background: var(--primary);
}
.main-bnr .back-circle {
  bottom: -10%;
  left: -10%;
}
.main-bnr .back-circle2 {
  top: -10%;
  right: -10%;
}
/* responsive */
@media screen and (max-width: 1199px) {
  .site-button,
  .site-button-secondry {
    position: relative;
    z-index: 1;
    overflow: hidden;
    vertical-align: middle;
    padding: 10px 16px;
    font-size: 12px;
  }
  .banner-content {
    padding-top: 120px;
  }
  .main-bnr h5 {
    font-size: 16px;
  }
  .main-bnr h1 {
    font-size: 55px;
    line-height: 70px;
  }
  .banner-media .banner-media-bg {
    width: 800px;
    height: 800px;
  }
  .find-job-bx.style-1 form {
    padding: 16px 20px 16px 20px;
  }
  .find-job-bx.style-1 form .site-button {
    padding: 14px 20px;
  }
}
@media screen and (max-width: 1024px) {
  .main-bnr h5 {
    font-size: 14px;
  }
  .main-bnr h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
  .main-bnr h6 {
    font-size: 14px;
  }
  .main-bnr .banner-content .dz-searches h4 {
    font-size: 14px;
  }
  .main-bnr .banner-content .dz-searches span {
    font-size: 14px;
  }
  .banner-media .banner-media-bg {
    width: 750px;
    height: 750px;
  }
}
@media screen and (max-width: 991px) {
  .banner-content {
    padding-top: 0;
  }
  .banner-media .banner-main-media {
    text-align: center;
    height: 600px;
  }
  .banner-media .banner-main-media img {
    height: 100%;
  }
  .main-bar.style-1 .nav.navbar-nav li.active a i.fa-chevron-down {
    color: #000;
  }
  .find-job-bx.style-1 {
    max-width: 642px;
  }
}
@media screen and (max-width: 767px) {
  .main-bnr h1 {
    margin-bottom: 10px;
  }
  .find-job-bx.style-1 {
    max-width: unset;
  }
  .find-job-bx.style-1
    form
    .form-group
    .input-group
    .form-control::placeholder {
    font-size: 14px;
  }
  .find-job-bx.style-1 form {
    padding: 14px 20px 14px 20px;
  }
  .main-bnr .back-circle {
    display: none;
  }
  .main-bnr .back-circle2 {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .banner-media .banner-main-media {
    height: 500px;
  }
  .banner-media .banner-media-bg {
    width: 650px;
    height: 650px;
  }
  .banner-media .banner-media-bg .bnr-circle1 .banner-icon1 {
    width: 40px;
  }
  .banner-media .banner-media-bg .bnr-circle2 .banner-icon1 {
    width: 60px;
  }
  .banner-media .banner-media-bg .bnr-circle2 .banner-icon2 {
    right: -12%;
    width: 60px;
  }
  .banner-media .banner-media-bg .bnr-circle2 .banner-icon3 {
    width: 70px;
  }
  .main-bnr h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (max-width: 480px) {
  .dz-searches {
    display: none !important;
  }
  .main-bnr h1 {
    font-size: 35px;
    line-height: 40px;
  }
  .banner-media .banner-main-media {
    height: 400px;
  }
  .banner-media .banner-media-bg {
    width: 500px;
    height: 500px;
  }
  .find-job-bx.style-1 form {
    padding: 14px 20px 14px 20px;
    display: block;
  }
  .find-job-bx.style-1 form .form-group {
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
  }
  .find-job-bx-inner,
  .dz-input-group {
    display: block;
  }
  .find-job-bx-inner1 {
    width: 100%;
  }
  .dz-input-group-inner {
    width: 100%;
  }
}

/* Banner-End */
/* Partners */
.partners-media {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.partners-media img {
  width: 100%;
  height: 100%;
  filter: grayscale(1);
  opacity: 0.4;
}

/* Partners-End */
/* About-Work */
.about-work .section-head.style-1 p {
  max-width: 520px;
}
.icon-bx-wraper.style-1 {
  padding: 30px;
  text-align: center;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.5s;
  background-color: #fff;
}
.icon-bx-wraper.style-1:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: var(--primary);
  z-index: -1;
  transition: all 0.5s;
}
.icon-bx-wraper.style-1 .icon-box {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto 20px;
  border-radius: 6px;
  transition: all 0.5s;
  position: relative;
  background-color: var(--primary);
}

.icon-bx-wraper.style-1 .icon-content .dez-tilte {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  color: #232323;
  transition: all 0.5s;
  position: relative;
}

.icon-bx-wraper.style-1 .icon-content .dz-text {
  margin-top: 10px;
  margin-bottom: 0;
  transition: all 0.5s;
}
.icon-bx-wraper.style-1:hover:after {
  height: 100%;
}
.icon-bx-wraper.style-1:hover .icon-content .dez-tilte {
  text-decoration: none;
  color: #fff;
}

.icon-bx-wraper.style-1:hover .icon-content .dz-text {
  color: #fff;
}
.icon-bx-wraper.style-1:hover .icon-box {
  background-color: #fff;
}

.icon-bx-wraper.style-1:hover .icon-box svg path {
  fill: var(--primary);
}
.about-work .back-circle {
  position: absolute;
  width: 400px;
  height: 400px;
  bottom: -40%;
  left: -10%;
  opacity: 0.3;
  filter: blur(250px);
  background: var(--primary);
  z-index: 1;
}

/* responsive */
@media screen and (max-width: 1600px) {
  .about-work .back-circle {
    width: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 1199px) {
  .icon-bx-wraper.style-1 {
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .icon-bx-wraper.style-1 .icon-content .dez-tilte {
    font-size: 18px;
  }
  .icon-bx-wraper.style-1 .icon-content .dz-text {
    margin-top: 6px;
  }
  .about-work .back-circle {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .icon-bx-wraper.style-1 .icon-box {
    width: 55px;
    height: 55px;
    margin: auto auto 15px;
  }
  .icon-bx-wraper.style-1 .icon-box svg {
    width: 30px;
  }
  .about-work .back-circle {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .about-work-inner {
    display: flex;
  }
  .icon-wrapper {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .about-work-inner {
    display: unset;
  }
  .icon-wrapper {
    width: unset;
  }
}
/* About-Work-End */
/* Our-Job */
.recent-jobs {
  z-index: unset;
  overflow: hidden;
}
.recent-jobs .section-head.style-1 h6 {
  margin-bottom: 8px;
}
.recent-jobs .section-head.style-1 .section-title-2 {
  max-width: 575px;
}
.recent-jobs .section-head.style-1 p {
  max-width: 520px;
  margin-bottom: 30px;
}
.recent-jobs .section-head.style-1 .site-button.style-1 {
  padding: 15px 30px;
}
.dz-job-media {
  position: relative;
  z-index: 1;
  width: 50vw;
  float: left;
}
.dz-job-media img {
  width: 597px;
  height: 100%;
  left: -40px;
  position: relative;
  mask-image: url(../images/back-mask3.png);
  -webkit-mask-image: url(../images/back-mask3.png);
  -webkit-mask-position: -70px -132px;
}
.dz-job-media svg {
  /* content: "";
    background-image: url(../images/back-mask.png); */
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 870px;
  height: 708px;
  position: absolute;
  /* top: -19px; */
  bottom: 1px;
  left: 0px;
  z-index: -1;
}
.dz-job-media svg path {
  fill: var(--primary);
}
/* responsive */
@media screen and (max-width: 1400px) {
  .recent-jobs .section-head.style-1 .section-title-2 {
    max-width: 520px;
  }
}
@media screen and (max-width: 1199px) {
  .dz-job-media img {
    width: 520px;
    left: -30px;
    -webkit-mask-position: -52px -214px;
  }
  .dz-job-media:after {
    width: 760px;
  }
}
@media screen and (max-width: 1024px) {
  .dz-job-media img {
    width: 450px;
    left: -60px;
    -webkit-mask-position: -50px -288px;
  }
  .dz-job-media:after {
    width: 760px;
  }
  .dz-job-media:after {
    width: 660px;
    left: -30px;
    bottom: -1px;
  }
}
@media screen and (max-width: 991px) {
  .dz-job-media {
    display: none;
  }
  .recent-jobs .section-head.style-1 {
    text-align: center;
    margin-bottom: 0;
  }
  .recent-jobs .section-head.style-1 .section-title-2 {
    max-width: unset;
  }
  .recent-jobs .section-head.style-1 p {
    margin: 0 auto 25px auto;
  }
}
@media screen and (max-width: 767px) {
  .recent-jobs .section-head.style-1 {
    text-align: unset;
  }
  .recent-jobs .section-head.style-1 p {
    margin: 0 0 20px 0;
    max-width: unset;
  }
  .recent-jobs .section-head.style-1 .site-button.style-1 {
    padding: 12px 22px;
    font-size: 14px;
  }
}
/* Our-Job-End */
/* Job Category */
.job-category {
  z-index: 1;
}
.job-category .section-head.style-1 p {
  max-width: 650px;
}
.job-category .site-button.style-1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}
.job-bx-wraper .icon-content {
  text-align: center;
  padding: 30px;
  background: var(--rgba-primary-1);
  border-radius: 6px;
  position: relative;
  z-index: 1;
}
.job-bx-wraper .icon-content:after {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--primary);
  z-index: -1;
  opacity: 0;
  transition: all 0.5s;
}
.job-bx-wraper:hover .icon-content:after {
  width: 100%;
  opacity: 1;
}
.job-bx-wraper .icon-content .job-name {
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.5s;
}
.job-bx-wraper:hover .icon-content .job-name {
  color: #fff;
}
.job-bx-wraper .icon-content span {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  transition: all 0.5s;
}
.job-bx-wraper:hover .icon-content span {
  color: #fff;
}
.job-category .back-circle {
  position: absolute;
  width: 200px;
  height: 200px;
  background: var(--primary);
  opacity: 0.3;
  filter: blur(200px);
  right: 0;
  top: 10%;
}
.job-category .back-circle2 {
  position: absolute;
  width: 400px;
  height: 400px;
  background: var(--primary);
  opacity: 0.3;
  filter: blur(200px);
  left: -15%;
  bottom: -60%;
}

/* responsive */
@media screen and (max-width: 1600px) {
  .job-category .back-circle {
    width: 200px;
    height: 200px;
    right: 0%;
    top: 25%;
  }
}
@media screen and (max-width: 1199px) {
  .job-bx-wraper .icon-content {
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .job-bx-wraper .icon-content .job-name {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .job-bx-wraper .icon-content span {
    font-size: 14px;
    line-height: 24px;
  }
  .job-category .site-button.style-1 {
    font-size: 18px;
  }
  .job-category {
    overflow: hidden;
  }
  .job-category .back-circle2 {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .wrapper-spacing {
    justify-content: center;
  }
}
@media screen and (max-width: 575px) {
  .job-category .back-circle {
    display: none;
  }
  .wrapper-spacing {
    display: flex;
  }
  .job-wraper {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .wrapper-spacing {
    display: unset;
  }
  .job-wraper {
    width: unset;
  }
}
/* Job Category-End */
/* Latest jobs */
.latest-jobs-inner {
  display: flex;
  align-items: end;
  margin-bottom: 40px;
}
.latest-jobs-inner .section-head.style-1 {
  margin-bottom: 0;
  flex: 1;
}
.latest-jobs-inner .section-head.style-1 p {
  padding-top: 0;
}
.job-wrapper {
  background: #fff;
  padding: 30px;
  box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: relative;
  transition: all 0.5s;
  border: 2px solid rgba(46, 85, 250, 0);
}
.job-wrapper:hover {
  border: 2px solid var(--primary);
}
.job-wrapper .dz-timing {
  position: absolute;
  top: 10px;
  right: 10px;
}
.job-wrapper .dz-timing span {
  font-size: 11px;
  font-weight: 400;
  margin-right: 10px;
}
.job-wrapper .dz-timing a {
  font-size: 11px;
  font-weight: 500;
  color: var(--primary);
  background: var(--rgba-primary-2);
  padding: 3px 7px;
  border-radius: 2px;
}
.job-wrapper .jobs-profile {
  margin-bottom: 15px;
}
.job-wrapper .jobs-profile .dz-icon {
  width: 55px;
  height: 55px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.job-wrapper .jobs-profile .Profile-inner .profile-name {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}
.job-wrapper .jobs-profile .Profile-inner .profile-position {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 0;
}
.job-wrapper .Profile-inner-2 p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}
.job-wrapper .Profile-inner-2 .dz-buttons {
  justify-content: space-between;
}
.job-wrapper .Profile-inner-2 .dz-buttons .site-button.style-1 {
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
}
.job-wrapper .Profile-inner-2 .dz-buttons .dz-salary {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.job-wrapper .Profile-inner-2 .dz-buttons .dz-salary span {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary);
  letter-spacing: 0.02em;
  margin-right: 3px;
}
.jobs-btn {
  text-align: center;
}

/* responsive */
@media screen and (max-width: 991px) {
  .job-wrapper {
    padding: 20px;
  }
  .job-wrapper .jobs-profile {
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .job-wrapper .Profile-inner-2 p {
    margin-bottom: 15px;
    line-height: 22px;
  }
}
@media screen and (max-width: 480px) {
  /* .latest-jobs{
		text-align: center;
	} */
  .latest-jobs-inner {
    display: unset;
    margin-bottom: unset;
    text-align: center;
  }
  .latest-jobs-inner .section-head.style-1 {
    margin-bottom: 15px;
    text-align: center;
  }
  .latest-jobs .latest-jobs-inner .site-button.style-1 {
    margin-bottom: 20px;
  }
  .job-wrapper .jobs-profile .dz-icon {
    width: 45px;
    height: 45px;
  }
  .job-wrapper .jobs-profile .dz-icon img {
    width: 25px;
  }
  .job-wrapper .jobs-profile .Profile-inner .profile-name {
    font-size: 14px;
  }
  .job-wrapper .jobs-profile .Profile-inner .profile-positions {
    font-size: 14px;
  }
  .job-wrapper .Profile-inner-2 .dz-buttons .site-button.style-1 {
    font-size: 12px;
    padding: 10px 15px;
  }
  .job-wrapper .Profile-inner-2 .dz-buttons .dz-salary span {
    font-size: 16px;
  }
}
/* Latest jobs-End */
/* Reviews Testimonial */
.testimonials .section-head.style-1 {
  margin-bottom: 30px;
}
.review-testimonial .item {
  padding: 60px 0 20px;
}
.testimonial-wrapper {
  background: var(--rgba-primary-05);
  border-radius: 6px;
}
.companynamee {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin: 10px;
  color: black;
}
.companynamee span {
  color: var(--primary);
  font-weight: 400;
  margin-left: 10px;
  font-size: 12px;
}
.testimonial-pic.style-1 {
  width: 105px;
  height: 105px;
  position: absolute;
  top: 0;
  left: 50%;
  border: 0;
  transform: translate(-50%, -55%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}
.testimonial-pic.style-1 .profile-pic {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonial-pic-circle {
  position: absolute;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  border: 2px solid var(--primary);
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  animation: testimonial-circle 30s linear infinite;
}
@keyframes testimonial-circle {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.testimonial-pic-circle:after,
.testimonial-pic-circle:before {
  position: absolute;
  content: "";
  background: var(--primary);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 50%;
}
.testimonial-pic-circle:after {
  top: -1px;
  transform: translate(-50%, -50%);
}
.testimonial-pic-circle:before {
  bottom: -1px;
  transform: translate(-50%, 50%);
}
.testimonial-wrapper .testimonial-inner {
  padding: 65px 20px 20px;
  position: relative;
  background: var(--rgba-primary-05);
  text-align: center;
  border-radius: 6px;
  position: relative;
  height: 300px;
}
.brandlogo-t {
  height: 41px;
  width: 100px !important;
  width: auto;
  object-fit: contain;
  position: absolute;
  top: 19px;
  right: 5px;
}
.testimonial-wrapper .testimonial-inner .bg-img {
  position: absolute;
  top: 30px;
  left: 30px;
}
.testimonial-wrapper .testimonial-inner .bg-img svg path {
  fill: var(--primary);
}
.testimonial-wrapper .testimonial-inner .profile-info {
  padding-bottom: 10px;
}
.testimonial-wrapper .testimonial-inner .profile-info .profile-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 0;
}
.testimonial-wrapper .testimonial-inner .profile-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--primary);
}
.review-testimonial .owl-nav .owl-prev,
.review-testimonial .owl-nav .owl-next {
  transform: unset;
}
.review-testimonial .owl-prev,
.review-testimonial .owl-next {
  width: 42px;
  height: 42px;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: var(--rgba-primary-1);
  justify-content: center;
  color: var(--primary);
  opacity: unset;
  transition: all 0.5s;
}
.review-testimonial .owl-prev,
.review-testimonial .owl-next,
.review-testimonial .owl-prev,
.review-testimonial .owl-next {
  margin: 0 -100px !important;
}
.review-testimonial .owl-prev:hover,
.review-testimonial .owl-next:hover {
  background-color: var(--primary);
  color: #fff;
  opacity: 1;
}

/* responsive */
@media screen and (max-width: 1380px) {
  .review-testimonial .owl-prev,
  .review-testimonial .owl-next,
  .review-testimonial .owl-prev,
  .review-testimonial .owl-next {
    margin: 0 0 !important;
  }
  .review-testimonial .item {
    padding: 60px 0 60px;
    width: 47%;
  }
  .review-testimonial .owl-prev,
  .review-testimonial .owl-next {
    top: auto !important;
    bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .testimonials .section-head.style-1 h2 {
    max-width: 400px;
    margin: auto;
  }
}
/* Reviews Testimonial-End */
/* Modal Box */
.find-jobs {
  position: relative;
  z-index: 0;
}
.find-jobs:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  background: var(--rgba-primary-05);
  bottom: 0;
}
.find-jobs-inner {
  background: var(--primary);
  display: flex;
  align-items: center;
  padding: 40px 70px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.find-jobs-inner .section-head.style-1 {
  margin-bottom: 0;
  flex: 1;
}
.find-jobs-inner .section-head.style-1 h3 {
  margin-bottom: 0;
  color: #fff;

  font-size: 35px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0.01em;
  max-width: 500px;
}
.find-jobs-inner .site-button.style-1 {
  color: var(--primary);
  background: #fff;
  font-weight: 400;
  padding: 15px 40px;
  line-height: 19px;
}
/* responsive */
@media screen and (max-width: 1024px) {
  .find-jobs-inner .section-head.style-1 h3 {
    font-size: 30px;
    line-height: 38px;
  }
}
@media screen and (max-width: 991px) {
  .find-jobs-inner .section-head.style-1 h3 {
    font-size: 30px;
    line-height: 34px;
  }
  .find-jobs-inner {
    padding: 40px;
  }
}
@media screen and (max-width: 767px) {
  .find-jobs-inner {
    display: block;
    text-align: center;
    padding: 30px 50px;
  }
  .find-jobs-inner .section-head.style-1 {
    margin-bottom: 20px;
  }
  .find-jobs-inner .site-button.style-1 {
    padding: 12px 22px;
  }
}
@media screen and (max-width: 575px) {
  .find-jobs-inner .section-head.style-1 h3 {
    font-size: 25px;
    line-height: 32px;
  }
}
/* Modal Box-End */
/* Footer */
.footer-top.style-1 {
  padding: 70px 0 20px;
  background: var(--rgba-primary-05);
}
.logo-footer.style-1 {
  margin-top: unset;
  margin-bottom: 15px;
  height: unset;
}
.logo-footer.style-1 img {
  max-width: 180px;
}
.logo-footer.style-1.logo-white {
  display: none;
}
.widget-logo a {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary);
}
.widget-logo a svg {
  margin-right: 15px;
}
.widget.style-1 h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #142238;
  margin-top: 5px;
}
.widget.style-1 ul li,
.widget.style-1 p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #444444;
}
.widget.style-1 ul li {
  margin-bottom: 12px;
}
.widget.style-1 ul li:last-child {
  margin-bottom: 0;
}
.widget.style-1 ul li a {
  color: #444444;
}
.dzSubscribe.style-1 .input-group.style-1 .form-control {
  position: relative;
  width: 100%;
  height: 60px;
  border-radius: 8px !important;
  color: #444444;
  font-size: 16px;
  border: 2px solid var(--primary);
  margin: 0;
}
.dzSubscribe.style-1 .input-group.style-1 .form-control::placeholder {
  color: #444444;
}
.dzSubscribe.style-1 .input-group.style-1 .input-group-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-10%, -50%);
  padding: 0;
  z-index: 9;
}
.dzSubscribe.style-1
  .input-group.style-1
  .input-group-btn
  .site-button.style-1 {
  padding: 12px 15px;
  height: 45px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
}
.widget-links,
.widget-categories,
.widget-social {
  margin-left: 20px;
}

/* Footer-bottom */
.footer-bottom.style-1 {
  background: var(--rgba-primary-05);
  padding: 20px 0;
  color: #444444;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid var(--rgba-primary-1);
  letter-spacing: 0.01em;
}
.footer-bottom.style-1 a {
  color: var(--primary);
}

/* responsive */
@media screen and (max-width: 1199px) {
  .widget-links,
  .widget-categories,
  .widget-social {
    margin-left: unset;
  }
  .dz-job-media svg {
    width: 600px;
    height: 500px;
    bottom: -35px;
  }
  .dz-job-media img {
    width: 350px;
  }
}
@media screen and (max-width: 767px) {
  .logo-footer.style-1 {
    margin-bottom: 20px;
  }
  .widget.style-1 h5 {
    margin-bottom: 10px;
  }
  .widget.style-1 ul li,
  .widget.style-1 p {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 575px) {
  .dzSubscribe.style-1 .input-group.style-1 .form-control {
    height: 50px;
    font-size: 14px;
  }
  .dzSubscribe.style-1
    .input-group.style-1
    .input-group-btn
    .site-button.style-1 {
    padding: 10px 15px;
    height: 40px;
    font-size: 14px;
  }
  .review-testimonial .owl-nav {
    display: none;
  }
  .review-testimonial .item {
    padding: 60px 0 30px;
    width: 100%;
  }
}

/* Footer-End */
