.chat-window {
  height: calc(100vh - 75px);
  // background-color: rgb(232, 232, 255);
  display: flex;

  .chat-leftbar {
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #eee;
    padding: 5px;
    width: 350px;
    overflow: auto;
    .chat-seaarch-user {
      border: 2px solid $blue1color;
      border-radius: 6px;
      .MuiInputBase-root {
        height: 38px;
        input::placeholder {
          color: rgb(73, 72, 72);
        }
      }
    }
  }
  .chat-rightbar {
    flex: 1;
    background-color: #f5f4f4;
  }

  @media (max-width: 800px) {
    .chat-leftbar {
      display: none;
    }
    .full-application-text {
      display: none;
    }
  }

  .chat-message-box {
    display: flex;
    flex-direction: column;
    height: 100%;

    .chat-body {
      flex: 1;
      overflow: auto;
      padding: 15px;
      .chat-text-box {
        text-align: left;
        &.incoming {
          text-align: right;
          .chat-text-wrapper {
            background-color: #0a80ff;
            color: #fff;
            small {
              color: #ebebeb;
            }
          }
        }

        .chat-text-wrapper {
          padding: 10px 15px;
          border-radius: 4px;
          max-width: 85%;
          display: inline-block;
          background-color: #fff;
          margin-bottom: 15px;
          p {
            font-size: 14px;
            text-align: left;
            font-weight: 500;
          }
          small {
            font-size: 11px;
            color: #000;
            font-weight: 300;
          }
        }
      }
    }
    .chat-footer {
      background-color: #d5d5d5;
      display: flex;
      align-items: center;
      padding: 12px;

      .chat-input {
        background-color: #fff;
        flex: 1;
      }
      .send-btn {
        height: 50px;
        margin-left: 10px;
        font-size: 18px;

        svg {
          margin-left: 10px;
        }
      }
    }
    .chat-header-action {
      display: flex;
      align-items: center;
    }
    .chat-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d5d5d5;
      padding: 17px 30px;

      .chat-header-user {
        display: flex;
        align-items: center;

        figure {
          display: inline-block;
          margin-bottom: 0;
          height: 2.3rem;
          width: 2.3rem;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          margin-right: 1rem;
          img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }
        h5 {
          font-size: 16px;
          text-transform: capitalize;
          font-weight: 600;
          margin-bottom: 0;
          line-height: 1;
        }
        small {
          font-size: 12px;
          color: #0a80ff;
        }
      }
    }
  }
}
.chat-header-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin: 0 10px;
  cursor: pointer;
  list-style-type: none;

  .chat-user-box {
    padding: 16px 15px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 5px;

    &.active,
    &:hover {
      transition: 0.5s;
      background-color: #ededed;
    }

    .users-list-body {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      position: relative;
      min-width: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;

      div:first-child {
        min-width: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }

      h5 {
        white-space: nowrap;
        text-transform: capitalize;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        line-height: 1;
      }

      p {
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;
        font-size: 12px;
        color: #969696;
      }
      .student-profile {
        color: $blue1color;
        text-transform: capitalize;
        font-size: 12px;
      }
      .users-list-action {
        padding-left: 15px;
        .new-message-count {
          width: 23px;
          display: flex;

          align-items: center;
          justify-content: center;
          line-height: 0;
          font-size: 13px;
          height: 23px;
          background-color: #0a80ff;
          color: #fff;
          border-radius: 50%;
          margin-left: auto;
        }
        small {
          font-size: 11px;
        }
      }
    }

    figure {
      margin-right: 1rem;
      display: inline-block;
      margin-bottom: 0;
      height: 2.3rem;
      width: 2.3rem;
      border-radius: 50%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
  }
}

#choose-internship {
  text-transform: capitalize;
  font-size: 15px;
}
@media (max-width: 800px) {
  .chat-header-group .chat-user-box figure {
    margin-right: 4px;
    width: 2rem;
    .rounded-circle {
      width: 28px !important;
      height: 28px !important;
      font-size: 14px !important;
    }
  }
  .chat-header-group .chat-user-box .users-list-body h5 {
    font-size: 13px;
  }
  #choose-internship {
    font-size: 13px;
  }
  .chat-window .chat-message-box .chat-header {
    padding: 10px 10px;
  }
  .chat-window
    .chat-message-box
    .chat-body
    .chat-text-box
    .chat-text-wrapper
    p {
    font-size: 12px;
  }
  .chat-window .chat-message-box .chat-body .chat-text-box .chat-text-wrapper {
    max-width: 92%;
  }
  .chat-header-group .chat-user-box {
    padding: 10px 10px;
  }
}

#open-chat-user-btn {
  background-color: $secondary-color;
  color: #fff;
  margin-right: 6px;
  display: none;

  @media (max-width: 800px) {
    display: flex;
  }
}

.icon-btn {
  background-color: $secondary-color !important;
  color: #fff !important;
}
