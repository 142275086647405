.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 16px; /* Add padding to prevent content from touching screen edges */
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Adjusts modal width to fit small screens */
    max-width: 400px; /* Maintains a maximum width for larger screens */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    word-wrap: break-word; /* Ensures long words or text wraps to the next line */
  }
  
  .modalHeader {
    margin-top: 0;
    font-size: 18px; /* Slightly smaller font for mobile screens */
  }
  
  .modalDescription {
    margin-bottom: 20px;
    font-size: 14px; /* Smaller font for mobile readability */
  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 12px; /* Adds spacing between form elements */
  }
  
  .formRow {
    display: flex;
    align-items: flex-start;
    gap: 8px; /* Adds space between radio buttons and labels */
    flex-wrap: wrap; /* Ensures content wraps on small screens */
  }
  
  .radioLabel {
    font-size: 14px;
    font-weight: 300;
    color: #333;
    line-height: 1.4;
    flex: 1; /* Allows the label to take up available space */
  }
  
  .modalActions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px; /* Adds spacing between buttons */
    flex-wrap: wrap; /* Ensures buttons wrap on smaller screens */
  }
  
  .button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .buttonDisabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .buttonEnabled {
    background-color: #007bff;
    color: white;
  }
  
  .radioInput {
    margin-top: 4px; /* Ensures alignment for multiline labels */
  }
  