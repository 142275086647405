.dashboard-page-box {
  display: flex;
  // padding-top: 75px;
  .dashboard-page-content-wrapper {
    padding: 10px 10px 20px 280px;
    flex: 1;
    overflow-y: auto;
    position: relative;

    #open-admin-dashboard-btn {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    @media (max-width: 800px) {
      .application-rightsidebar {
        display: none;
      }
    }
    //padding-left: 320px;
    &.pl-0 {
      padding-left: 0;
    }
    .dashboard-contentbox {
      overflow: auto;
      padding-right: 10px;

      .application-box-wrapper {
        margin: 2%;
        position: relative;

        flex: 1;

        @media (max-width: 800px) {
          margin-top: 56px;
        }
      }
    }
  }
}

.circular {
  border-radius: 50%;
}

.page-title-2 {
  font-size: 32px;
  font-weight: 600;
  color: $brand-color;
}

.page-title-1 {
  font-size: 24px;
  font-weight: 600;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #787878;
}

.dashboard-sidebar-drawer {
  position: relative;
  padding-top: 48px;
}
.cancel-drawer-btn {
  position: absolute !important;
  top: 0;
  right: 6px;
  z-index: 10;

  @media (max-width: 800px) {
    display: block !important;
  }
  svg {
    color: $secondary-color;
  }
}

.mobile-filter-btn {
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}
.applicants-text {
  font-size: 16px;
  font-weight: 600;
  color: $secondary-color;
}
@media (max-width: 800px) {
  .mobile-filter-btn {
    display: block;
  }

  .dashboard-page-box .dashboard-page-content-wrapper {
    padding: 10px 10px;
  }
  .applicants-text {
    font-size: 12px;
  }
  .application-check-wrap {
  }
}
