@media (max-width: 600px) {
  .web-breadcremb .breadcrumb-content .breadcrumb-text {
    font-size: 14px;
    line-height: 1;
  }
  .web-breadcremb .breadcrumb-content .breadcrumb-subheading {
    font-size: 12px;
    line-height: 1;
  }
  .internship-info-box {
    margin-top: 30px;
  }
  .title2 {
    font-size: 22px;
  }
  .heading-box {
    div,
    p,
    li {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.3px;
    }
  }
}
