.custom-datatable {
    .MuiDataGrid-root {
      font-size: 18px;
    }
    .circular-btn {
      border-radius: 30px;
      text-transform: capitalize;
    }
    .MuiSelect-select {
      padding: 8px 12px;
    } 
  }