.internship-details-popup {
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiTypography-root {
  }
  #internship-details-title {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    color: $secondary-color;
  }
  .internship-company-logo {
    height: 40px;
    max-width: 70px;
    object-fit: contain;
  }
}
.MuiDialog-container {
  .popup-close-btn {
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    background: #f1f1f1;
    right: 20px;
    top: 15px;
    color: $secondary-color;
  }
}
