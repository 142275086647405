.custom-form {
  .small-txt {
    font-size: 9px;
    font-weight: 600;
    color: #000;
  }
  .input-label1 {
    font-size: 15px;
    font-weight: 500;
  }
  .form-inline-inputs {
    .react-datepicker-wrapper {
      width: 100%;
    }
    .input-label1 {
      margin-bottom: 10px;
    }
  }
  .assessment-headerbox {
    margin-bottom: 16px;
    border-bottom: 1px solid #eee;
    .assesment-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .assesment-label {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        color: #0a80ff;
      }
    }
  }

  .inpuxbox-group {
    border-bottom: 2px solid #eee;
    padding: 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 25px;
    border-radius: 12px;
  }
}

.MuiAutocomplete-tag {
  margin: 3px;
  max-width: calc(100% - 6px);
  font-size: 10px;
  padding: 0 2px;
  border-radius: 5px;
  .MuiChip-label {
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
  }
  .MuiSvgIcon-root {
    font-size: 18px;
  }
}

.MuiInputBase-root {
  input::placeholder {
    color: #888;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
  }
  .MuiAutocomplete-input {
    &::placeholder {
      color: #888;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;
    }
  }
}
.MuiAutocomplete-option {
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.6px;
}
.MuiAutocomplete-root {
  .MuiAutocomplete-tag {
    margin: 3px;
    max-width: calc(100% - 6px);
    font-size: 11px;
    text-transform: capitalize;
    padding: 0 2px;
    border-radius: 5px;
    background-color: #0a80ff;
    color: #fff;
    letter-spacing: 0.5px;
    .MuiChip-label {
      padding-left: 8px;
      padding-right: 8px;
      white-space: nowrap;
    }
    .MuiSvgIcon-root {
      font-size: 16px;
      color: #011769;
      background-color: #fff;
      border-radius: 50%;
      transition: 0.4s;
      &:hover {
        color: yellow;
        background-color: #011769;
      }
    }
  }
  .MuiOutlinedInput-root .MuiInputBase-input {
    &::placeholder {
      font-size: 14px;
    }
  }
}

.job-page-footer {
  text-align: center;
  background-color: #313030;
  color: #fff;
  padding: 20px;
  a {
    color: #0a80ff;
  }
}

.page-title {
  text-align: center;
  font-size: 2rem;
  margin: 40px 0;
  color: #000a30;
  font-weight: 800;
}
.heading-box {
  margin-bottom: 40px;
  position: relative;

  &::before {
    content: "";
    height: 1px;
    width: 300px;
    background-color: #696969;
    position: absolute;
    bottom: -7px;
    left: 0;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    color: #0a80ff;
    text-transform: capitalize;
    line-height: 1;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
  }
}
@media (max-width: 600px) {
  .MuiAutocomplete-option {
    font-size: 11px;
    min-height: 25px !important;
    padding: 0 12px !important;
  }
  .MuiDrawer-paper {
    min-width: 240px !important;
  }
}
